import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingRoutingModule } from './syncproduct-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { SyncproductComponent } from './syncproduct.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [SyncproductComponent],
  imports: [
    FormsModule,
    NgbModule,
    CommonModule,
    SettingRoutingModule,
    SharedModule,
    NgbModule,
  ]
})
export class SyncproductModule { }
