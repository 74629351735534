<div class="jesssu-seller-banner-wrap">
  <div class="jesssu-seller-img">
    <img src="../../../../assets/images/Rectangle 1493.svg" />
  </div>
  <div class="jesssu-seller-contant">
    <div class="jesssu-header">
      <div class="jesssu-header-row">
        <div>
        </div>
        <div class="rightside-profile">

        </div>

      </div>
    </div>
    <!-- <div class="jesssu-heading">
      <div class="heading-row">
        <div class="jesssu-heading-name">
          <h4>Jesssu-Coin</h4>
        </div>
        <div class="contant-row">
          <h6>Home - Jesssu-Coin</h6>
        </div>
      </div>

    </div> -->

    <div class="user-applications-box">
      <div class="upload-heading">
      </div>
      <div class="row newrow">
        <div class="col-md-4">
          <div class="form-group">
            
            <div >
              <ng-autocomplete [data]="dataforuserlist" [searchKeyword]="keyword" placeholder="Select or search user by name or email"
                (selected)='selectuserEvent($event)' (inputCleared)='onremoveuserselection($event)' (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
        
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>
        
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-md-4" >
          <div class="form-group">
            <div >
              <div *ngIf="setSearch">
              <ng-autocomplete [data]="datafortypelist" [searchKeyword]="keyword" placeholder="Filter by Jesssu coin earning type"
                (selected)='selecttypeEvent($event)' (inputCleared)='onremovetypeselection($event)' (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            </div>
        
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>
        
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-md-3" >
          <div class="form-group">
            <div >
              <div *ngIf="setSearch">
              <ng-autocomplete [data]="dataforstatuslist" [searchKeyword]="keyword" placeholder="Select txn status"
                (selected)='selectstatusEvent($event)' (inputCleared)='onremovestatusselection($event)' (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
            </div>
        
              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>
        
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-2">
          <div class="product-feed-action">
            <button
              type="button"
              class="btn-action-link float-right"
              value="Submit"
              (click)="dataSubmit(filterform)"
            >
              Submit 
            </button>
          </div>
        </div> -->
      </div>
    
      <div class="upload-heading">
        <div class="row newrow">
        <p>Transactions Based on: </p><p *ngIf="selecteduser=='' && selectedtype=='' && selectedstatus==''">All list</p>   <p *ngIf="selecteduser!=''">(User = {{selecteduser}})</p> <p *ngIf="selectedtype!=''">(Txn Type = {{selectedtype}})</p> <p *ngIf="selectedstatus!=''">(Status = {{selectedstatus}})</p></div>
        <h4>Count - {{pendingtransactiondata.length}} </h4>
      </div>

      <div class="fixTableHead">

        <table class="table table-striped custom-table">
          <thead>
            <tr>
              <th scope="col">USER</th>
              <th scope="col">ACTIVITY DETAILS</th>
              <th scope="col">COIN AMOUNT </th>
              <th scope="col">TYPE</th>
              <th scope="col">ACTIVITY DATE</th>
              <th scope="col">STATUS</th>
              <th scope="col">COIN PROCESS</th>

            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let data of pendingtransactiondata">

              <td align="center" class="contact-us-name-heading">
                <h6>{{data.userdetails[0].firstname}} {{data.userdetails[0].lastname}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{data.txnremarks}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{data.txnamount}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{data.txntype}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{data.txndate | date:'d MMMM YYYY'}}</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>{{data.txnstatus}}</h6>
              </td>
              <td align="center" class="contact-email">
                <div class="product-feed-action" *ngIf="data.txnstatus=='Pending'">
                  <form id="productForm" class="needs-validation product-add" novalidate="">
                    <button type="submit" class="btn-action-link" value="Submit"
                      (click)="approvetransaction(data)">Process Coin</button>
                  </form>
                </div>
              </td>

            </tr>

            <!-- <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Pritam Nandi</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Received for share a <br>
                  product by Suman Saha</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Share Bonus</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>31/01/2022 </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Requested</h6>
              </td>
              <td align="center" class="contact-email">
                <div class="product-feed-action">
                  <form id="productForm" class="needs-validation product-add" novalidate="">
                    <button type="submit" class="btn-action-link" value="Submit">Process Coin</button>
                  </form>
                </div>
              </td>

            </tr>


            <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Pritam Nandi</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Received for share a <br>
                  product by Suman Saha</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Share Bonus</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>31/01/2022 </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Requested</h6>
              </td>
              <td align="center" class="contact-email">
                <div class="product-feed-action">
                  <form id="productForm" class="needs-validation product-add" novalidate="">
                    <button type="submit" class="btn-action-link" value="Submit">Process Coin</button>
                  </form>
                </div>
              </td>

            </tr>


            <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Pritam Nandi</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Received for share a <br>
                  product by Suman Saha</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Share Bonus</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>31/01/2022 </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Requested</h6>
              </td>
              <td align="center" class="contact-email">
                <div class="product-feed-action">
                  <form id="productForm" class="needs-validation product-add" novalidate="">
                    <button type="submit" class="btn-action-link" value="Submit">Process Coin</button>
                  </form>
                </div>
              </td>

            </tr>


            <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Pritam Nandi</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Received for share a <br>
                  product by Suman Saha</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Share Bonus</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>31/01/2022 </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Requested</h6>
              </td>
              <td align="center" class="contact-email">
                <div class="product-feed-action">
                  <form id="productForm" class="needs-validation product-add" novalidate="">
                    <button type="submit" class="btn-action-link" value="Submit">Process Coin</button>
                  </form>
                </div>
              </td>

            </tr>
            <tr>

              <td align="center" class="contact-us-name-heading">
                <h6>Pritam Nandi</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Received for share a <br>
                  product by Suman Saha</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>8</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Share Bonus</h6>
              </td>
              <td align="center" class="contact-email">
                <h6>31/01/2022 </h6>
              </td>
              <td align="center" class="contact-email">
                <h6>Requested</h6>
              </td>
              <td align="center" class="contact-email">
                <div class="product-feed-action">
                  <form id="productForm" class="needs-validation product-add" novalidate="">
                    <button type="submit" class="btn-action-link" value="Submit">Process Coin</button>
                  </form>
                </div>
              </td>
            </tr> -->
          </tbody>
        </table>
        <div class="response" *ngIf="success">
          <span class="closebtn" (click)="close()">&times;</span>
          <strong>Success!</strong> {{successtext}}
        </div>
      </div>
    </div>
  </div>
</div>