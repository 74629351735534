<nav class="navbar-main navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/home"><img src="../../../assets/images/jesssu-seller-logo.svg" alt="" /></a>
    <button class="navbar-toggler" type="button" (click)=toggglebtn()>
        <span class="navbar-toggler-icon">Test</span>
    </button>
    
    <div class="nav-end navbar-collapse nav_view">
        <form class="form-inline my-2 my-lg-0">
            <div class="name-section">
                <select name="COO" [(ngModel)]="credentialforsignup.COO" class="product-form-control" placeholder="Select Country of Origin">
                <option value="INDIA">
                    INDIA
                </option>
                <option value="USA">
                    USA
                </option>
            </select></div>
            <button class="premium-row btn btn-outline-success my-2 my-sm-0" type="submit"
                (click)="redirecttoplan()"><img src="../../../assets/images/crown.svg" />Plan & Pricing</button>
            <!-- <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="redirectedtologin()">Sign up</button>
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="redirectedtologin()">Register</button> -->
            <button type="button" class="landing-action-link" (click)="redirectedtologin()">Register/Login</button>
        </form>
    </div>
    <div class="nav-end navbar-collapse" *ngIf="!Shownav">
        <form class="form-inline my-2 my-lg-0">
            <div class="name-section"><select name="COO" [(ngModel)]="credentialforsignup.COO" class="product-form-control" placeholder="Select Country of Origin">
                <option value="INDIA">
                    INDIA
                </option>
                <option value="USA">
                    USA
                </option>
            </select></div>
            <button class="premium-row btn btn-outline-success my-2 my-sm-0" type="submit"
                (click)="redirecttoplan()"><img src="../../../assets/images/crown.svg" />Plan & Pricing</button>
            <!-- <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="redirectedtologin()">Sign up</button>
        <button class="btn btn-outline-success my-2 my-sm-0" type="submit" (click)="redirectedtologin()">Register</button> -->
            <button type="button" class="landing-action-link" (click)="redirectedtologin()">Register/Login</button>
        </form>
    </div>

</nav>
<div class="headerwrap">
    <div class="headerwrap-box-wrapp container">
        <div class="headerwrap-box">
            <div class="header-wrap-row">
                <div class="header-img">
                    <img src="../../../assets/images/home-font-img.svg" alt="" class="landing-page-img" />
                </div>
                <div class="col-md-7 seller-jesssu-header">
                    <h1>Want to become a <br><span>seller on Jesssu?</span></h1>
                </div>
                <!-- <div class="search-row">
                    <div class="premium-section-row">
                        <img src="../../../assets/images/Jesssu.svg" alt="" />
                        <div class="premium-section">
                            <div class="premium-row" (click)="redirecttoplan()">
                                <img src="../../../assets/images/premium.png" alt="" />
                                <h5>Plan and pricing</h5>
                            </div>
                        </div>
                    </div>
                    <div class="register-row">
                        <div class="form-group">
                             <input type="text" class="landing-form-control" placeholder="Enter your mobile number"
                                name="manufacture_part_number"> -->
                <!-- </div>
                        <div class="profile-feed-action">
                            <button type="button" class="landing-action-link"(click)="redirectedtologin()">Register/Login</button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="middle-wrap-content">
    <div class="col-md-11">
        <div class="type-info">
            <div class="type-img">
                <img src="../../../assets/images/ten-minutes.svg" alt="" />
                <h6>10 minutes Onboarding</h6>
            </div>
            <div class="type-img">
                <img src="../../../assets/images/proper-data.svg" alt="" />
                <h6>Proper Data & Analysis</h6>
            </div>
            <div class="type-img">
                <img src="../../../assets/images/listing-price.svg" alt="" />
                <h6>90%+ listing Passthrough</h6>
            </div>
            <!-- <div class="type-img">
                <img src="../../../assets/images/free-shopping.svg" alt="" />
                <h6>Free Shipping Policy</h6>
            </div> --> <!--No Shipping Policy-->
            <div class="type-img">
                <img src="../../../assets/images/mobile-app.svg" alt="" />
                <h6>New Mobile App</h6>
            </div>


        </div>
    </div>
</div>

<div class="landing-heading-row">
    <div class="headingbox">
        <h2>How it Works</h2>
    </div>
</div>

<div class="jesssu-home-row">
    <div class="col-md-2">
        <div class="create-box">
            <div class="create-account-row">
                <img src="../../../assets/images/one.svg" alt="" />
                <h5>Create Account</h5>
            </div>
            <div class="create-box-content">
                <h6>All you need is :</h6>
                <div class="create-row">
                    <img src="../../../assets/images/dot.svg" alt="" />
                    <p>GSTIN</p>
                </div>
                <div class="create-row">
                    <img src="../../../assets/images/dot.svg" alt="" />
                    <p>Bank Account</p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="create-box">
            <div class="create-account-row">
                <img src="../../../assets/images/two.svg" alt="" />
                <h5>List Product</h5>
            </div>
            <div class="create-content">
                <h6>List your products you<br> want to sell in your <br>product List section.</h6>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="create-box">
            <div class="create-account-row">
                <img src="../../../assets/images/three.svg" alt="" />
                <h5>Get Orders</h5>
            </div>
            <div class="create-content">
                <h6>Start getting the orders and manage your orders in your sell order</h6>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="create-box">
            <div class="create-account-row">
                <img src="../../../assets/images/four.svg" alt="" />
                <h5>Lowest Shipping <br>Cost</h5>
            </div>
            <div class="create-content">
                <h6>Products are shipped to customers at lowest costs</h6>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="create-box">
            <div class="create-account-row">
                <img src="../../../assets/images/five.svg" alt="" />
                <h5>Receive Payments</h5>
            </div>
            <div class="create-content">
                <h6>Safe and secure payment deposit directly in your account.</h6>
            </div>
        </div>
    </div>

</div>

<div class="grow-business-wrap">
    <div class="grow-heading-wrap">
        <img src="../../../assets/images/business-banner.png" alt="" />

        <!-- <div class="grow-row">
            <div class="grow-heading-section">
                <h3>Grow your business <br>with us</h3>
                <div class="create-row">
                    <img src="../../../assets/images/dot.svg" alt="" />
                    <p>Easy access to your sell and purchase data</p>
                </div>
                <div class="create-row">
                    <img src="../../../assets/images/dot.svg" alt="" />
                    <p>View your daily or monthly earnings</p>
                </div>
                <div class="create-row">
                    <img src="../../../assets/images/dot.svg" alt="" />
                    <p>View and manage your sell orders</p>
                </div>
            </div>
            <img src="../../../assets/images/grow.png" alt="" />
        </div> -->
    </div>
</div>

<div class="seller-business-wrap">
    <div class="seller-heading-wrap">
        <div class="grow-seller-row">
            <div class="grow-heading-section">
                <h3>Jesssu supplier support is <br>available 24/7</h3>
            </div>
            <div class="support-row">
                <h6>Our supplier support is available to solve all your doubts and issues <br>before and after you
                    start your online selling business.</h6>
                <div class="mail-row">
                    <img src="../../../assets/images/Icon ionic-ios-mail.svg" alt="" />
                    <h6>Get in touch with us at <strong><a
                                href="mailto:support@jesssu.com">support@jesssu.com</a></strong></h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="seller-home-footer-wrap">
    <div class="seller-home-row">
        <div class="footer-app-store-section">
            <div class="footer-home-img">
                <img src="../../../assets/images/jesssu-seller.png" alt="" />
                <h3>Download the <br>Seller Central App on</h3>
                <div class="play-row">
                    <img src="../../../assets/images/google-play-badge.png" alt="" style="cursor:pointer"
                        (click)="gotoplaystore()" />
                    <img src="../../../assets/images/app-store.png" alt="" style="cursor:pointer; width: 8.8rem"
                        (click)="gotoapplestore()" />
                </div>
            </div>
        </div>
        <div class="footer-img-section">
            <div class="seller-home-footer-img">
                <img src="../../../assets/images/Onboarding.png" alt="" />
                <img src="../../../assets/images/Sign_up.png" alt="" />
                <img src="../../../assets/images/Quick add product.png" alt="" />
            </div>
        </div>
    </div>
</div>

<div *ngIf="applestore" class="loginbox">
    <div class="paste-source">
        <div class="paste-source-box">
            <h4>IOS app is under progress. Will be available very soon </h4>
            <div class="save-btn">
                <div class="featured-action">
                    <button type="button" class="save-upload-cli-primary-btn" (click)="closeapplestorepopup()">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>