<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper"><a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
    src="../../../../assets/images/jesssu admin logo.svg" alt=""></a></div>
</div>
<div class="sidebar custom-scrollbar">
  <div class="sidebar-user text-center" *ngIf="this.admindata==null">
    <div><img class="img-60 rounded-circle" src={{sellerdata[0].profileimage}} onerror="this.onerror==null; this.src='assets/images/dashboard/man.png'" alt="" >
    </div>
    <h6 class="mt-3 f-14">{{sellerdata[0].firstname}} {{sellerdata[0].lastname}}</h6>
    <p>{{sellerdata[0].manufacturer}}</p>
  </div>
  <div class="sidebar-user text-center" *ngIf="this.sellerdata!=null && this.admindata!=null">
    <button type="button" 
                                                  class="btn-action-link" (click)="backtoadminview()">Back to admin view</button></div>
  <div class="sidebar-user text-center" *ngIf="this.admindata!=null">
    <div><img class="img-60 rounded-circle" src="assets/images/dashboard/man.png" alt="#">
    </div>
    <h6 class="mt-3 f-14">Hello Admin!</h6>
    <p *ngIf="this.sellerdata==null">No Seller Selected</p>
    <p *ngIf="this.sellerdata!=null">Selected Seller: {{sellerdata[0].firstname}} {{sellerdata[0].lastname}}</p>
    <p *ngIf="this.sellerdata!=null">{{sellerdata[0].manufacturer}}</p>
  </div>
 
  <ul class="sidebar-menu">
    <li *ngFor="let menuItem of menuItems" [ngClass]="{active: menuItem.active}">
      <!-- Sub -->
      <a href="javascript:void(0)" class="sidebar-header" *ngIf="menuItem.type === 'sub'"
        (click)="toggletNavActive(menuItem)"> 
        <!-- <app-feather-icons [icon]="menuItem.icon"></app-feather-icons> -->
        <img src={{menuItem.image}}> 
        <span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
            <!-- <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>  -->
      </a>
      <!-- Link -->
      <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-header"
        *ngIf="menuItem.type === 'link'">
        <!-- <app-feather-icons [icon]="menuItem.icon"></app-feather-icons> -->
        <img src={{menuItem.image}}> 
        <span>{{menuItem.title}} <i class="fa fa-right-arrow pull-right" *ngIf="menuItem.children"></i> <span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-right-arrow pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- External Link -->
      <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-header" *ngIf="menuItem.type === 'extLink'">
        <!-- <app-feather-icons [icon]="menuItem.icon"></app-feather-icons> -->
        <img src={{menuItem.image}}>  
        <span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- External Tab Link -->
      <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-header"
        *ngIf="menuItem.type === 'extTabLink'">
        <!-- <app-feather-icons [icon]="menuItem.icon"></app-feather-icons> -->
        <img src={{menuItem.image}}> 
        <span>{{menuItem.title}}<span
            class="badge badge-{{menuItem.badgeType}} ml-3"
            *ngIf="menuItem.badgeType">{{menuItem.badgeValue}}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
       <!-- External Tab Link -->
      
      <!-- 2nd Level Menu -->
      <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
        *ngIf="menuItem.children">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
            <i class="fa fa-circle"></i><span> {{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span> </span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- Link -->
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- External Tab Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
            *ngIf="childrenItem.type === 'extTabLink'">
            <i class="fa fa-circle"></i><span>{{childrenItem.title}} <span
                class="badge badge-{{childrenItem.badgeType}} pull-right"
                *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue}}</span></span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>
          <!-- 3rd Level Menu -->
          <ul class="sidebar-submenu" *ngIf="childrenItem.children">
            <li *ngFor="let childrenSubItem of childrenItem.children">
              <!-- Link -->
              <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-circle"></i><span> {{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span> </span>
              </a>
              <!-- External Link -->
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-circle"></i><span>{{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span></span>
              </a>
              <!-- External Tab Link -->
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                *ngIf="childrenSubItem.type === 'extTabLink'">
                <i class="fa fa-circle"></i><span>{{childrenSubItem.title}} <span
                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue}}</span></span>
              </a>
             
            </li>
          </ul>
           
        </li>
      </ul>
    </li>
  </ul>
</div>
