import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/service/api.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit {
  public userfriends = [];
  id: any;
  admindata: any;
  sellerdata: any;
  public offset: number = 0;
  public count: number = 10;
  constructor(private apiService: ApiService, private router: Router) {
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
   }

  ngOnInit(): void {
    this.getfriendlist()
  }

  getfriendlist() {
    
    this.id = JSON.parse(window.localStorage.getItem("url"));
    console.log(this.id)
    if (this.id != null && this.id != '0') {
      const payload = {
        useremail: this.id.email,
        token: this.admindata.token
      }
      this.apiService.getuserfrienddata(this.offset,this.count, payload)
        .subscribe((useractivity: any) => {
          this.userfriends = useractivity;
        });
    }
    
  }

}
