import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../../shared/service/api.service';
import { ProductimageService } from '../../shared/service/fileupload.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  sellerdata: any;
  admindata: any;
  subscriptiontype: string;

  constructor(private apiService: ApiService, private router: Router, private imageuploadService: ProductimageService) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
  }

  ngOnInit(): void {
  }

  getsubscription(data) {
    // debugger;
    if (this.sellerdata == null || this.sellerdata == undefined) {
      this.router.navigateByUrl("/auth/login")
    }
    else {
      this.router.navigateByUrl(`/payment-details/${data}`)
    }
  }



}
