import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { UserSellerComponent } from './components/user-seller/user-seller.component';
import { StartpageComponent } from './components/startpage/startpage.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
// import { PaymentComponent } from './components/payment/payment.component';
import { ErrorComponent } from './components/error/error.component';
import { JesssucoinComponent } from './components/jesssucoin/jesssucoin.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { FriendsComponent } from './components/friends/friends.component';
import { PolicyComponent } from './components/policy/policy.component';
import { SyncproductComponent } from './components/syncproduct/syncproduct.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'page-not-found',
    component: ErrorComponent,
  },
  {
    path: 'privacy-policy',
    component: PolicyComponent,
    children: content
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: StartpageComponent,
  },
  {
    path: 'subscription-selection',
    component: SubscriptionComponent,
  },
  // {
  //   path: 'payment-details/:type',
  //   component: PaymentComponent,
  // },
  {
    path: 'jesssucoin',
    component: JesssucoinComponent,
  },
  {
    path: 'contactus',
    component: ContactusComponent,
  },
  {
    path: 'friends',
    component: FriendsComponent,
  },
  {
    path:'syncproduct',
    component: SyncproductComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
