import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserModel } from 'src/app/shared/models/datamodel';

@Component({
  selector: 'app-startpage',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.scss']
})
export class StartpageComponent implements OnInit {
  sellerdata: any;
  admindata: any;
  applestore: boolean = false;
  public credentialforlogin = new UserModel();
  public credentialforsignup = new UserModel();

  constructor(private router: Router) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
  }

  ngOnInit(): void {

  }

  redirectedtologin() {
    this.router.navigateByUrl("/auth/login")
  }
  redirecttoplan() {
    this.router.navigateByUrl("/subscription-selection")
  }
  gotoplaystore(){
    window.open("https://play.google.com/store/apps/details?id=com.seller.jesssu")
  }
  gotoapplestore(){
    this.applestore = true
  }
  closeapplestorepopup(){
    this.applestore = false
  }

  Shownav = true;  
    
  toggglebtn() {  
    this.Shownav = !this.Shownav;  
  }  

}
