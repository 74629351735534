<h4>BRANDS DETAILS</h4>

<main class="container">

    <!-- Left Column -->
    <div class="left-column img">
        <label class="upload-imgbox" for="img01">
            <img src={{brandlist[0].branddetails[0].image}} />
        </label>
        <h4> {{brandlist[0].branddetails[0].brandname}} </h4>
        <div class="image-upload-box">
            <input type="file" id="img01" (change)="selectFile($event,'main')" />
            <label class="upload-imgbox" for="img01">
            </label>
        </div>
        <button class="btn-action-link" (click)="onSubmit(brandlist[0].branddetails[0])">Update</button>
    </div>

    <div *ngIf="popupshow" class="loginbox">
        <div class="paste-source">
          <div class="paste-source-box">
            <h4>{{popupboxvalue}}</h4>
            <div class="save-btn">
              <div class="featured-action">
                <button type="button" class="save-upload-cli-primary-btn"
                  (click)="onconfirm()">
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


</main>