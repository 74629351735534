<div class="jesssu-seller-banner-wrap">
  <div class="jesssu-seller-img">
    <img src="../../../../assets/images/Rectangle 1493.svg" />
  </div>
  <div class="jesssu-seller-contant">
    <div class="jesssu-header">
      <div class="jesssu-header-row">
        <div>
        </div>
        <div class="rightside-profile">
        </div>
      </div>
    </div>
    <div class="jesssu-heading">
      <div class="heading-row">
        <div class="jesssu-heading-name">
          <h4>Friends</h4>
        </div>
        <div class="contant-row">
          <h6>Home - Friends</h6>
        </div>
      </div>

    </div>

    <div class="friends-box">
      <div class="friends-heading-section">
        <h5>Friends-List</h5>
      </div>
      <div class="row">
        <div class="col-md-4" *ngFor="let friend of userfriends">
          <div class="friends-row">
            <div class="friends-img">
              <img src={{friend.profileimage[0]}}
              onerror="this.onerror=null; this.src='../../../assets/images/black-and-white-boy-casual-555790 (1).png'"
              alt=""/>
            </div>
            <div class="friends-name">
              <h5>{{friend.friendfirstname[0]}} {{friend.friendlastname[0]}}</h5>
              <!-- <p>{{}}</p> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/lady.png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/boy.png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/black-and-white-boy-casual-555790 (1).png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/lady.png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/boy.png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/black-and-white-boy-casual-555790 (1).png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/lady.png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="friends-row">
            <div class="friends-img">
              <img src="../../../assets/images/boy.png " alt="" />
            </div>
            <div class="friends-name">
              <h5>John Miachel</h5>
              <p>25 Mutual Friends</p>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</div>