<!-- <div class="card-body tab2-card"> -->

<div class="jesssu-seller-banner-wrap">
    <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.8.2/angular.min.js"></script>
    <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                    <!-- <input type="text" name="search" placeholder="Search.." class="search-form-control"> -->
                </div>
                <div class="rightside-profile">
                    <!-- <div class="rightside-img">
                            <img src="../../../../assets/images/bell.svg" />
                        </div> -->
                    <!-- <div class="rightside-profilt-bar-img">
                            <img src={{userdata.gst_file_link}} class="profileimg"/>
                        </div> -->

                    <!-- <div class="image-upload-box-header" style="color:white;">
                        <input type="file" id="pt03" style="display:none" />
                        <label class="upload-imgbox-header" for="pt03">
                            <img src={{userdata.profileimage}}
                      onerror="this.onerror==null; this.src='../../../../assets/images/Ellipse 83.png'" alt=""
                      class="profileimg" />
                            <img src="../../../assets/images/black-and-white-boy-casual-555790 (1).png " alt="" />

                        </label>
                        <span>Profile Image</span>
                    </div> -->

                </div>

            </div>
        </div>
        <div class="jesssu-heading">
            <div class="heading-row">
                <div class="jesssu-heading-name">
                    <h4>User List</h4>
                </div>
                <div class="contant-row">
                    <h6>Home - User List</h6>
                </div>
            </div>

        </div>



        <div class="container-fluid" *ngIf="pageview">
            <div class="card">
                <!-- <div class="card-header">
                    <h4>Get Jesssu Platform Details :
                        <button class="btn-action-link" type="button" type="button" (click)="removeselectedseller()">{{
                            sellerdata||userdata != null?"Unselect User":"No User
                            Selected"}}
                        </button>
                    </h4>
                </div> -->
                <div class="card-body" style="overflow: auto;">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab>
                            <ng-template ngbTabTitle>Sellers</ng-template>
                            <ng-template ngbTabContent>

                                <div class="applications-box7">
                                    <div class="search-bar" style="align-items: end;">
                                        <input type="text" class="btn-form-control" id="myInputforseller"
                                            (keyup)="searchseller()" placeholder="Search sellers by name/email"
                                            autofocus>
                                    </div>
                                    <div class="fixTableHead">

                                        <table class="table table-striped custom-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SELECT
                                                        <!-- <label class="control control--checkbox">
                                                    <input type="checkbox" checked="checked" />
                                                    <div class="control__indicator"></div>
                                                </label> -->
                                                        <!-- <label class="container1">
                                                    <input type="checkbox" lebel="selection" value="seller" >
                                                    <span class="checkmark1">
                                                    </span>
                                                  </label> -->

                                                    </th>
                                                    <th scope="col">USER INFO</th>
                                                    <th scope="col">PHONE NO</th>
                                                    <th scope="col">Current Active Plan</th>
                                                    <th scope="col" class="text-center">STATUS</th>
                                                    <!-- <th scope="col">ACTIVE/DEACTIVATE USER</th> -->
                                                    <th scope="col">Reset Password</th>
                                                    <th scope="col">Activate Plan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let seller of sellers">
                                                    <td align="center">
                                                        <!-- <input type="checkbox" lebel="selection" value="seller"
                                                    (change)="onSelect($event,seller)"> -->
                                                        <label class="container1">
                                                            <input type="checkbox" value="seller" id="{{seller._id}}"
                                                                (change)="onSelect($event,seller)">
                                                            <span class="checkmark1"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {{seller.firstname}} {{seller.lastname}}
                                                    </td>
                                                    <td class="pl-0">
                                                        <div class="d-flex align-items-center">

                                                            <a>{{seller.phone}}</a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{seller?.subscriptions?.plan}}
                                                    </td>
                                                    <!-- <td>{{seller.IsActive?"Active":"Deactive"}}</td> -->
                                                    <td class="text-center">
                                                        <label class="custom-control ios-switch" style="padding: 0;">
                                                            <input type="checkbox" class="ios-switch-control-input"
                                                                id="{{seller.firstname}}"
                                                                [checked]="seller.IsActive!==false" lebel="changestatus"
                                                                (click)="changeStatus(seller)">{{seller?.IsActive==false?checked:unchecked}}
                                                            <span class="ios-switch-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td class="pl-0">
                                                        <button type="button" class="btn-action-link1"
                                                            (click)="resetPassword(seller)">
                                                            {{seller?.IsActive==false?"Set Password":" Reset Password"}}</button>
                                                    </td>
                                                    <td class="pl-0">
                                                        <button type="button" class="btn-action-link1"
                                                            (click)="addPasswordAndSubscription(seller)">
                                                            Activate Subscription</button>
                                                    </td>
                                                    <!-- <td><a href="#" class="more"
                                                            (click)="Onclick3(seller._id)">Details</a></td> -->

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </ng-template>
                        </ngb-tab>

                        <ngb-tab>
                            <ng-template ngbTabTitle>Users</ng-template>
                            <ng-template ngbTabContent>


                                <div class="user-applications-box">

                                    <input type="text" class="btn-form-control" id="myInputforuser"
                                        (keyup)="searchuser()" placeholder="Search for users" autofocus>

                                    <div class="fixTableHead">

                                        <table class="table table-striped custom-table">
                                            <thead>
                                                <tr>
                                                    <!-- <th scope="col">Select
                                                        <label class="control control--checkbox">
                                                    <input type="checkbox" checked="checked" />
                                                    <div class="control__indicator"></div>
                                                </label>
                                                    </th> -->
                                                    <th scope="col">USER INFO</th>
                                                    <th scope="col">FRIENDS</th>
                                                    <th scope="col">USER ACTIVITY</th>
                                                    <th scope="col">USER ONBOARDING SCREEN</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let user of jessuplatformusers">

                                                    <!-- <td align="center">
                                                        <label class="container1">
                                                            <input type="checkbox" id="{{user._id}}" value="user"
                                                                (change)="onSelect1($event,user)">
                                                            <span class="checkmark1"></span>
                                                        </label>
                                                    </td> -->


                                                    <td>
                                                        {{user.firstname}} {{user.lastname}}
                                                    </td>
                                                    <td class="pl-0">
                                                        <button type="button" class="btn-action-link1"
                                                            (click)="Onclickcheckfriends(user)">Get
                                                            User
                                                            Friends</button>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn-action-link1"
                                                            (click)="Onclickcheckactivitydetails(user)">Get
                                                            User
                                                            Details</button>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn-action-link1"
                                                            (click)="Onclickcheckscreendetails(user)">Get
                                                            Screen Details</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>


                        <!-- <ngb-tab>
                            <ng-template ngbTabTitle>Notifications From Seller</ng-template>
                            <ng-template ngbTabContent>
                                <div class="applications-box6">
                                    <div class="applications-table-row">
                                        <div class="col-md-6">
                                            <div class="order-row2">
                                                <h4>Target User</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <select name="acc_name" [(ngModel)]="notificationModel.target_user"
                                                    class="submit-form-control">
                                                    <option>Seller</option>
                                                    <option>Customer</option>
                                                </select>
                                                <input type="text" placeholder="." class="submit-form-control"
                                    [(ngModel)]="notificationModel.target_user" />
                                            </div>
                                            <div class="order-row2">
                                                <h4>Message</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <input type="text" placeholder="" class="submit-form"
                                                    [(ngModel)]="notificationModel.message">

                                            </div>
                                            <div class="order-row2">
                                                <h4>Notification Title</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <input type="text" placeholder="." class="submit-form-control"
                                                    [(ngModel)]="notificationModel.notification_title" />
                                            </div>
                                            <div class="feed-action">

                                                <form #notificationForm="ngForm" (ngSubmit)="onSubmit(notificationForm)"
                                                    id="notificationForm" class="needs-validation notification-add"
                                                    novalidate="">
                                                    <router-outlet (activate)="onActivate($event)"></router-outlet>
                                                    <div class="user-submit-row">
                                                        <button class="feed-action-link btn-action-link" type="submit"
                                                            value="Submit">Submit</button>
                                                        <div class="alert" *ngIf="error">
                                                            <span class="closebtn"
                                                                onclick="this.parentElement.style.display='none';">&times;</span>
                                                            <strong>Error!</strong> {{errortext}}
                                                        </div>
                                                        <div class="response" *ngIf="success">
                                                            <span class="closebtn"
                                                                onclick="this.parentElement.style.display='none';">&times;</span>
                                                            <strong>Success!</strong> {{successtext}}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="user-upload-img">
                                                <h3>Notification Image</h3>
                                                <div class="image-upload-box">
                                                    <input type="file" id="pt09" (change)="selectFile($event,'main')" />
                                                    <label class="upload-imgbox" for="pt09">
                                                        <img src={{notificationModel.notification_image}}
                                                            onerror="this.onerror==null; this.src='../../../../../assets/images/dashboard/upload.svg'"
                                                            alt="" />

                                                    </label>
                                                </div>
                                                <input type="file" id="main" class="upload-img"
                                        (change)="selectFile($event,'main')" />
                                    <img class="upload-img" src={{notificationModel.notification_image}} alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>







                            </ng-template>
                        </ngb-tab> -->
                    </ngb-tabset>
                </div>
            </div>
        </div>

        <div class="popup" *ngIf="showPopup">
            <div class="popup-content">
              <span class="close" (click)="closePopup()">&times;</span>
              <h2 class="popup-title">Choose a plan for {{selectedSellerForPlan?.email}}</h2>
              <div id="buttons-container">
                <button *ngFor="let button of plans; let i = index" (click)="getsubscription(button)" class="btn-action-link1">
                    {{button.title}} Plan
                </button>
                <button (click)="closePopup()" class="btn-action-link1" style="background-color: rgb(183, 124, 124);">
                    Cancel
                </button>
              </div>
              
            </div>
          </div>
    </div>
</div>