export class User {
    public user_id: String;
    public firstname: String;
    public lastname: String;
}
export class Menu {
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
}


export class MENUITEMS {
    menuname: String;
    menus: Menu
}
export class Accounts {
    public acc_id: String;
    public acc_type: String;
    public acc_name: String;
    public isChecked: boolean;
}

export class Supplier {
    public suppliername: String;
    public supplierid: String;
}

export class PurchasePayment {
    public mode: String;
    public date: any;
    public amount: string;
    public notes: String;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
}

export class PurchaseProduct {
    public id: String;
    public subcategory: String;
    public item_name: String;
    public item_sku: String;
    public main_image_url: String;
    public quantity: Number;
    public productprice: Number;
}

export class Purchase {
    public slno: Number;
    public invoiceno: String;
    public manufacturer: String;
    public user: User[];
    public supplier: Supplier;
    public products: PurchaseProduct[];
    public created_on: any;
    public updated_on: any;
    public invoice_date: any;
    public due_date: any;
    public payment: PurchasePayment;
    public purchaseinvoice: String;
    public lastprice: String;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
    public email: String;
}

export class Purchaseorder { 
    public slno: Number;
    public invoiceno: String;
    public manufacturer: String;
    public user: User[];
    public supplier: Supplier;
    public products: PurchaseProduct[];
    public created_on: any;
    public updated_on: any;
    public invoice_date: any;
    public due_date: any;
    public payment: PurchasePayment;
    public purchaseinvoice: String;
    public total: Number;
    public outstanding: Number;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
    email: any;
}

export class Category {
    public category_id: String;
    public category_type: String;
    public category_name: String;
    public isChecked: boolean;
    _id: String
}

export class channel {
    public channel_name: String;
    public url: String;
}

export class Productfordashboard {
    main_image_url: String;
    item_name: String;
    business_price: Number;
    maximum_retail_price: Number;
    brand_name: String;
}

export class Newsupplier {
    user: User;
    public manufacturer: String;
    public supplier_type: String;
    public supplier_name: String;
    public email: String;
    public address: String;
    public mobile_no: String;
    public contact_person: String;
    public shipping_address: String;
    public tax_id: String;
    public buisness_details: String;
    public opening_balance: String;
    public opening_balance_type: String;
    public opening_date: String;
    public notes: String;
    suppiler: any;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String;
}

export class ProductModel {
    user: [User];
    ProductId: String;
    Name: String;
    SKU: String;
    Barcode: String;
    Manufacturer: String;
    PartNumber: String;
    Brand: String;
    Keywords: String;
    UPC: String;
    M1: String;
    Pixel: String;
    Gender: String;
    Primary: String;
    Secondary: String;
    Secondary1: String;
    Product: String;
    ProductImage: String;
    Short: String;
    Long: String;
    DiscountType: String;
    SalePrice: any;
    RetailPrice: any;
    ProductCurrency: String;
    ShippingAmount: String;
    Availability: String;
    ProductType: String;
    Size: String;
    Color: String;
    MainImage: String;
    COO: String;
    Categoryid: String;
    Category: String;
    Subcategory: String;
    DiscountCurrency: String;
    PriceCurrency: String;
    ShippingCurrency: String;
    Shopifyproductid: String;
    Shopifyproductvarientid: String;
    manufacturer: String;
    offertype: string;
    offerpercentage: string;
    offeramount: Number;
    subcategory: Category;
    parentsku: String;
    item_name: String;
    manufacture_part_number: String;
    item_sku: String;
    brand_name: String;
    part_number: String;
    external_product_id: String;
    external_product_id_type: String;
    recommended_browse_nodes: String;
    care_instructions1: String;
    care_instructions2: String;
    design_name: String;
    occasion_type1: String;
    occasion_type2: String;
    color_map: String;
    material_type: String;
    weave_type: String;
    department_name: String;
    item_length_description: String;
    standard_price: Number;
    quantity: String;
    condition_type: String;
    item_package_quantity: string;
    maximum_retail_price: Number;
    product_description: String;
    target_gender: String;
    model_name: String;
    main_image_url: String;
    other_image_url1: String;
    other_image_url2: String;
    other_image_url3: String;
    other_image_url4: String;
    other_image_url5: String;
    other_image_url6: String;
    other_image_url7: String;
    other_image_url: String;
    raw_other_image_url: String;
    raw_main_image_url: String;
    variation_theme: String;
    shipping_weight: String;
    weight: String;
    status: Boolean;
    colour: String;
    business_price: Number;
    pricing_action: String;
    key_product_description: String;
    key_product_description1: String;
    key_product_description2: String;
    legal_disclamier_description: String;
    generic_keywords: String;
    manufacturer_contact_information: String;
    launch_date: Date;
    number_of_items: string;
    country_region_of_origin: String;
    item_weight: String;
    country_of_origin: String;
    item_volume: String;
    safely_warning: String;
    positionplace: String;
    channellist: [];
    channels: channel[];
    height: Number;
    length: Number;
    breadth: Number;
    variations: ProductVariationModel[];
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String;
    public email: any;

}

export class ProductVariationModel {
    ProductId: String;
    Name: String;
    SKU: String;
    SalePrice: any;
    RetailPrice: any;
    ProductImage: String;
    Size: String;
    Color: String;
    Availability: String;
    number_of_items: string;
    other_image_url: string;
    other_image_url1: string;
    other_image_url2: string;

}
export class SaleOrderCount {
    _id: String;
    Count: String
}

export class ProductlistModel {
    Shopifyproductvarientid: any;
    Shopifyproductid: any;
  likes: any;
  data: any;
  dislikes: any;
  comments: any;
    SKU(SKU: any) {
        throw new Error('Method not implemented.');
    }
    public _id: String;
    public category: String;
    public subcategory: String;
    public parentsku: String;
    public manufacture_part_number: String;
    public item_sku: String;
    public item_name: String;
    public standard_price: String;
    public maximum_retail_price: String;
    public brand_name: String;
    public design_name: String;
    public color_map: String;
    public material_type: String;
    public weave_type: String;
    public external_product_id: String;
    public condition_type: String;
    public variation_theme: String;
    public colour: String;
    public product_description: String;
    public key_product_description: String;
    public key_product_description1: String;
    public key_product_description2: String;
    public shipping_weight: String;
    public item_weight: String;
    public launch_date: String;
    public country_region_of_origin: String;
    public safely_warning: String;
    public number_of_items: String;
    public main_image_url: String;
    public other_image_url: String;
    public other_image_url1: String;
    public other_image_url2: String;
    public other_image_url3: String;
    public other_image_url4: String;
    public other_image_url5: String;
    public other_image_url6: String;
    public status: Boolean;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
}

export class UserModel {
    public _id: string;
    public COO: string;
    public gst_number: string;
    public profileimage: string;
    public roleaccess: [];
    public gst_file_link: string;
    public profilecompletation: Number;
    public firstname: String;
    public lastname: String;
    public email: String;
    public role: String;
    public password: string;
    public confirmpassword: string;
    public newPassword: string;
    public age: string;
    public IsActive: boolean;
    public phone: String;
    public country: String
    public display_name: String
    public legal_trading_name: String
    public registration_id_number: String
    public tax_number: String
    public vat_number: String
    public logo: String
    public organisation_type: String
    public business_description: String
    public sector: String
    public vat_tax: String
    public financial_year_end: String
    public physical_address: String
    public postal_address: String
    public telephone: String
    public email2: String
    public website: String
    public twitter: String
    public facebook: String
    public linkedIn: String
    public otp: String
    public bank_acc_no: String;
    public branch_name: String;
    public ifsc_code: String;
    public manufacturer: String;
    public token: String;
    public userid: String;
    public paymentaddress: AddressModel
}

export class SubscriptionModel {
    public userid: String
    public manufacturer: String
    public orderid: String
    public subscriptiontype: String
    public paymentstatus: Boolean
    public paymentid: String
    public createdAt: Date;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
}

export class updatedchannel {
    public ChannelId: String;
}

export class NotificationModel {
    public target_user: string;
    public message: string;
    public notification_title: string;
    public notification_image: string;
    public tokens: any[];
    public token: any
}

export class ProductimageModel {
    public _id: String;
    public _productId: String;
    public main_image_url: String;
    public other_image_url1: String;
    public other_image_url2: String;
    public other_image_url3: String;
    public other_image_url4: String;
    public other_image_url5: String;
    public other_image_url6: String;
    public other_image_url7: String;
    public other_image_url8: String;
    public other_image_url: String;
    public ProductImage: String
}

export class PaymentModel {
    public _id: string;
    public Method: string;
    public Cardno: string;
    public Cvv: string;
    public Status: string;
}

export class couriorcheckModel {
    public pickup_postcode: Number;
    public delivery_postcode: Number;
    public order_id: Number;
    public cod: Boolean;
    public weight: String;
    public length: Number;
    public breadth: Number;
    public height: Number;
    public declared_value: Number;
    public mode: String;
    public is_return: Number;
    public token: String;
}

export default class UserPermission {
    _id: string;
    Title: String;
    Type: String;
    _userId: String;
    _permissionId: String;
    completed: boolean;
}

export class AddressModel {
    public _id: string;
    public Country: string;
    public Address: string;
    public Town: string;
    public Pincode: string;
    public State: string;
}

export class vendorModel {
    public _id: string;
    public firstName: String;
    public lastName: String;
    public email: String;
    public password: string;
    public confirmpassword: string;
    public Status: String;
    public exp: number;
    public iat: number;

}

export class BrandModel {
    user: User;
    user_image: string;
    branddetails_image: string;
    external_keywords: String;
    external_video_url: [];
}

export class salelist {
    fromdate: Date;
    todate: Date
}

export class timelysalelist {
    year: string;
    month: string
}

export class mastercategory {
    public category_type: string;
    public category_name: string;
}

export class datetime {
    fromdate: Date;
    todate: Date
}

export class datetimelist {
    year: string;
    month: string
}

export class Saleorder {
    public _id: string;
    public manufacturer: String;
    public slno: Number;
    public invoiceno: String;
    public type: String;
    public user: User[];
    public customer: Customer;
    public products: ProductsForSale[];
    public orderno: String;
    public courierno: String;
    public channel: String;
    public updated_on: String;
    public invoice_date: any;
    public filefordelivered: Filefordeliver[];
    public fileforreturn: Fileforreturn[];
    public payment: Payment;
    public status: Sale_Status[];
    current_status: String;
    public paymentforreturnstatus: Boolean;
    public paymentforreturn: Number;
    public fileforpayment: Fileforpayment[];
    public payment1: Payment[]
    SelectedProduct: any;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
    public email: String;
}

export class Sale_Status {
    public id: String;
    public current_status: String;
    public statustime: Date;
    public note: String;
    public filefordelivered: Filefordeliver[];
    public fileforreturn: Fileforreturn[];
    public paymentforreturnstatus: Boolean;
    public paymentforreturn: Number;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String

}

export class Sale_Status_Files {
    public link1: String;
    public link2: String;
    public link3: String;
    public link4: String;
}

export class Newcustomer {
    user: User;
    public customer_type: String;
    public customer_name: String;
    public email: String;
    public address: String;
    public mobile_no: String;
    public contact_person: String;
    public shipping_address: String;
    public tax_id: String;
    public buisness_details: String;
    public opening_balance: String;
    public opening_balance_type: String;
    public opening_date: String;
    public notes: String;
    public channel: String;
    public manufacturer: String;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
}

export class Customer {
    public customername: String;
    public customerid: String;
    public channel: String;
}

export class ProductsForSale {
    public id: String;
    public subcategory: String;
    public item_name: String;
    public item_sku: String;
    public main_image_url: String;
    public quantity: String;
    public ProductImage: String
    products: ProductsForSale[];
}

export class Fileforreturn {
    public filename: String;
    public filepath: String;
}
export class Filefordeliver {
    public filename: String;
    public filepath: String;
}
export class Fileforpayment {
    public filename: String;
    public filepath: String;
}

export class Payment {
    public channel: String
    public mode: String;
    public date: String;
    public amount: Number;
    public supplieramount: Number;
    public notes: String;
    public updatenotes: String;
    public user_id: String;
    public firstname: String;
    public lastname: String;
    public token: String
    // public fileforpayment: Fileforpayment[];
}

export class yymmwise {
    fromdate: Date;
    todate: Date
}

export class datewise {
    year: string;
    month: string
}


export class Productactivestatus {
    sale_order_id: string;
    relevent_details: string;
    sku: string;
    user_id: string;
    manufacturer: string;
    public firstname: String;
    public lastname: String;
    public token: String
   public email: String;
}
// Define the type for reaction data
interface ReactionData {
  likes: string[];
  dislikes: string[];
  comments: { user: string, comment: string }[];
}

// Initialize an empty reaction data object
let reactionData: ReactionData = {
  likes: [],
  dislikes: [],
  comments: []
};
