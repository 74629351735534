<!-- Page Content -->


<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-img">
        <img src="../../../../assets/images/Rectangle 1493.svg" />
    </div>
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <!-- <div class="jesssu-header-row">
                        <div>
                            <input type="text" name="search" placeholder="Search.." class="search-form-control">
                        </div>
                        <div class="rightside-profile">
                            <div class="rightside-img">
                                <img src="../../../../assets/images/bell.svg" />
                            </div>
                            <div class="rightside-profilt-bar-img">
                                <img src={{userdata.gst_file_link}} class="profileimg"/>
                            </div>

                            <div class="image-upload-box" style="color:white">
                            <input type="file" id="pt03" (change)="selectFile($event,'profile')" style="display:none"/>
                            <label class="upload-imgbox" for="pt03">
                                <img src={{userdata.profileimage}}
                                    onerror="this.onerror==null; this.src='../../../../assets/images/Ellipse 83.png'"
                                    alt="" class="profileimg"/>

                            </label>
                            <span>Profile Image</span>
                        </div>
                        
                        </div>
                        
                    </div> -->
        </div>
        <div class="jesssu-heading">
            <div class="heading-row">
                
                <!-- <div class="jesssu-heading-name">
                            <h4>Profile</h4>
                        </div>
                        <div class="contant-row">
                            <img src="../../../assets/images/Home (2).svg" alt="" />
                            <h6>Home - Profile</h6>
                        </div> -->
            </div>

        </div>

        <div class="fixTableHead">
           
            <div class="row">
                <div class="col-md-7">
                    <div class="profile-list-box">
                        <div class="profile-list-container">
                            <div class="sale-list-header">
                                <h3>Business Details</h3>
                            </div>
                            <div class="jesssu-profile-row">
                                <div class="col-md-6">
                                    <div class="company-name">
                                        <h5>Company/Store Name</h5>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="product-form-control" name="manufacturer"
                                            [(ngModel)]="userdata.manufacturer">
                                    </div>
                                    <div class="company-name">
                                        <h5>Registration No.</h5>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="product-form-control" name="registration_id_number"
                                            [(ngModel)]="userdata.registration_id_number">
                                    </div>
                                    <div class="company-name">
                                        <h5>Email</h5>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="product-form-control" name="email"
                                            [(ngModel)]="userdata.email">
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="company-name">
                                        <h5>Brand Name</h5>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="product-form-control" name="display_name"
                                            [(ngModel)]="userdata.display_name">
                                    </div>
                                    <div class="company-name">
                                        <h5>Phone Number</h5>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="product-form-control" name="phone"
                                            [(ngModel)]="userdata.phone">
                                    </div>
                                    <div class="company-name">
                                        <h5>Website</h5>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" class="product-form-control" name="website"
                                            [(ngModel)]="userdata.website">
                                    </div>
                                </div>

                            </div>
                            <div class="company-name">
                                <h5>Address</h5>
                            </div>
                            <div class="form-group">
                                <input type="text" class="product-form-control" name="physical_address"
                                    [(ngModel)]="userdata.physical_address">
                            </div>
                            <div class="company-name">
                                <h5>Business Description</h5>
                            </div>
                            <div class="form-group">
                                <input type="text" class="profile-form-control" name="business_description"
                                    [(ngModel)]="userdata.business_description">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="sale-list-box">
                        <div class="tax-list-container">
                            <div class="sale-list-header">
                                <h3>Tax Details</h3>
                            </div>
                            <div class="company-name">
                                <h5>Tax Number</h5>
                            </div>
                            <div class="form-group">
                                <input type="text" class="product-form-control" name="tax_number"
                                    [(ngModel)]="userdata.tax_number">
                            </div>
                            <div class="company-name">
                                <h5>Vat Number</h5>
                            </div>
                            <div class="form-group">
                                <input type="text" class="product-form-control" name="vat_number"
                                    [(ngModel)]="userdata.vat_number">
                            </div>
                            <div class="company-name">
                                <h5>GST Number</h5>
                            </div>
                            <div class="form-group">
                                <input type="text" class="product-form-control" name="gst_number"
                                    [(ngModel)]="userdata.gst_number">
                            </div>
                        </div>
                    </div>

                    <div class="sale-list-box">
                        <div class="tax-list-container">
                            <div class="gst-file-row">
                                <!-- <div class="gst-img">
                                            <img src="../../../../assets/images/gst-file.svg" alt="" />
                                            GST Invoice
                                        </div> -->
                                <div class="image-upload-col">
                                    <div class="image-upload-box">
                                        <input type="file" id="img01" (change)="selectFile($event,'main')"
                                            style="display:none" />
                                        <label for="img01">
                                            <img src={{userdata.profileimage}}
                                                onerror="this.onerror==null; this.src='../../../../assets/images/dashboard/Upload-Transparent.png'"
                                                alt="" class="profileimg" />

                                        </label>

                                    </div>
                                    <span class="img-bottom-heading">Profile Picture</span>
                                </div>
                                <div class="profile-feed-action">
                                    <button type="button" class="btn-action-link"
                                        (click)="profileupdate()">Update</button>
                                </div>
                                
                            </div>
                            <div class="alert" *ngIf="error">
                                <span class="closebtn" (click)="close()">&times;</span>
                                <strong>Error!</strong> {{errortext}}
                            </div>
                            <div class="response" *ngIf="success">
                                <span class="closebtn" (click)="close()">&times;</span>
                                <strong>Success!</strong> {{successtext}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


































<!-- Container-fluid starts-->
<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <img src="assets/images/dashboard/designer.jpg" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded">
                        <h5 class="f-w-600 mb-0">John deo</h5>
                        <span>johndeo@gmail.com</span>
                        <div class="social">
                            <div class="form-group btn-showcase">
                                <button class="btn social-btn btn-fb d-inline-block">
                                    <i class="fa fa-facebook"></i></button>
                                <button class="btn social-btn btn-twitter d-inline-block"><i
                                        class="fa fa-google"></i></button>
                                <button class="btn social-btn btn-google d-inline-block mr-0"><i
                                        class="fa fa-twitter"></i></button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="project-status">
                        <h5 class="f-w-600">Employee Status</h5>
                        <div class="media">
                            <div class="media-body">
                                <h6>Performance<span class="pull-right">80%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-primary" role="progressbar" style="width: 90%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Overtime <span class="pull-right">60%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                        <div class="media">
                            <div class="media-body">
                                <h6>Leaves taken<span class="pull-right">70%</span></h6>
                                <div class="progress sm-progress-bar">
                                    <div class="progress-bar bg-danger" role="progressbar" style="width: 70%"
                                        aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ngb-tabset class="tab-coupon profile-tabs">
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <app-feather-icons [icon]="'user'"></app-feather-icons>Profile</ng-template>
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <h5 class="f-w-600"> Profile</h5>
                                    <div class="table-responsive profile-table">
                                        <table class="table table-responsive">
                                            <tbody>
                                                <tr>
                                                    <td>First Name:</td>
                                                    <td>Johan</td>
                                                </tr>
                                                <tr>
                                                    <td>Last Name:</td>
                                                    <td>Deo</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>johndeo@gmail.com</td>
                                                </tr>
                                                <tr>
                                                    <td>Gender:</td>
                                                    <td>Male</td>
                                                </tr>
                                                <tr>
                                                    <td>Mobile Number:</td>
                                                    <td>2124821463</td>
                                                </tr>
                                                <tr>
                                                    <td>DOB:</td>
                                                    <td>Dec, 15 1993</td>
                                                </tr>
                                                <tr>
                                                    <td>Location:</td>
                                                    <td>USA</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>
                                <i data-feather="settings"></i> Contact</ng-template>
                            <ng-template ngbTabContent>
                                <div class="account-setting">
                                    <h5 class="f-w-600">Notifications</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="chk-ani">
                                                <input class="checkbox_animated" id="chk-ani" type="checkbox">
                                                Allow Desktop Notifications
                                            </label>
                                            <label class="d-block" for="chk-ani1">
                                                <input class="checkbox_animated" id="chk-ani1" type="checkbox">
                                                Enable Notifications
                                            </label>
                                            <label class="d-block" for="chk-ani2">
                                                <input class="checkbox_animated" id="chk-ani2" type="checkbox">
                                                Get notification for my own activity
                                            </label>
                                            <label class="d-block mb-0" for="chk-ani3">
                                                <input class="checkbox_animated" id="chk-ani3" type="checkbox"
                                                    checked="">
                                                DND
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="account-setting deactivate-account">
                                    <h5 class="f-w-600">Deactivate Account</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="edo-ani">
                                                <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani"
                                                    checked="">
                                                I have a privacy concern
                                            </label>
                                            <label class="d-block" for="edo-ani1">
                                                <input class="radio_animated" id="edo-ani1" type="radio" name="rdo-ani">
                                                This is temporary
                                            </label>
                                            <label class="d-block mb-0" for="edo-ani2">
                                                <input class="radio_animated" id="edo-ani2" type="radio" name="rdo-ani"
                                                    checked="">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary">Deactivate Account</button>
                                </div>
                                <div class="account-setting deactivate-account">
                                    <h5 class="f-w-600">Delete Account</h5>
                                    <div class="row">
                                        <div class="col">
                                            <label class="d-block" for="edo-ani3">
                                                <input class="radio_animated" id="edo-ani3" type="radio" name="rdo-ani1"
                                                    checked="">
                                                No longer usable
                                            </label>
                                            <label class="d-block" for="edo-ani4">
                                                <input class="radio_animated" id="edo-ani4" type="radio"
                                                    name="rdo-ani1">
                                                Want to switch on other account
                                            </label>
                                            <label class="d-block mb-0" for="edo-ani5">
                                                <input class="radio_animated" id="edo-ani5" type="radio" name="rdo-ani1"
                                                    checked="">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-primary">Delete Account</button>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Container-fluid Ends-->