import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './friends-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FriendsComponent } from './friends.component';


@NgModule({
  declarations: [FriendsComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    FormsModule,
    NgbModule
  ]
})
export class FriendsModule { }
