import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/service/api.service';
// import { map } from "rxjs/operators/map";
import { ProductModel, SubscriptionModel, UserModel } from "../../shared/models/datamodel";
import { Router } from '@angular/router';
import { NotificationModel } from '../../shared/models/datamodel';
import { ProductimageService } from '../../shared/service/fileupload.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-jesssucoin',
  templateUrl: './jesssucoin.component.html',
  styleUrls: ['./jesssucoin.component.scss']
})
export class JesssucoinComponent implements OnInit {
  keyword = 'name';
  sellerdata: any;
  public filterform: FormGroup;
  pendingtransactiondata = [];
  admindata: any;
  public userdata = new UserModel();
  public planname: String;
  public planprice: number = 0;
  finalplanpriceforcheckout: any;
  public orderid: any;
  public todaydate = new Date();
  public Manufacturer: any
  token: any;
  dataforuserlist = []
  datafortypelist = []
  dataforstatuslist = [
    {"id":"Approved",
    "name":"Approved"},
    {"id":"Pending",
    "name":"Pending"}
  ] 
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false
  public selecteduser = ''
  public selectedtype = ''
  public selectedstatus = ''
  public setSearch = false;
  jessuplatformusers: any[];
  constructor(private apiService: ApiService, public router: Router, public productimageservice: ProductimageService) {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    if (this.admindata == null) {
      this.router.navigate(['/page-not-found'])
    }
  }

  ngOnInit(): void {
    this.getjesssucointransactionlist()
    this.getplatformusers()
  }


  getjesssucointransactionlist() {
    const payload = {
      status: this.selectedstatus,
      user: this.selecteduser,
      txntype: this.selectedtype,
      token: this.admindata[0].token
    }
    this.apiService.getpendingcoindetails(payload)
      .subscribe((response: any[]) => {
        console.log(response)
        this.pendingtransactiondata = response
      });
  }

  getplatformusers() {
    this.dataforuserlist = []
    this.apiService.getjessuplatformUsers(this.admindata[0].token)
      .subscribe((jesssuusers: any[]) => {
        jesssuusers.forEach(i => {
          let userdata = {
            id: i.email,
            name: i.firstname + ' ' + i.firstname + '-' + i.email
          }
          this.dataforuserlist.push(userdata)
        })
        this.jessuplatformusers = jesssuusers
      });
  }

  selectuserEvent(item: any) {
    // debugger;
    this.selecteduser = item.id
    this.getjesssucointransactionlist()
    let user = {
      user: item.id,
      token: this.admindata[0].token
    }
    this.datafortypelist = []
    this.apiService.getjesssucoinearningtypes(user)
      .subscribe((response: any[]) => {
        console.log(response)
        response.forEach(i => {
          let typedata = {
            id: i._id,
            name: i._id
          }
          this.datafortypelist.push(typedata)
          this.setSearch = true;
        })
      })



    // do something with selected item
  }
  selecttypeEvent(item: any) {
    // debugger;
    this.datafortypelist = []
    this.selectedtype = item.id
    this.getjesssucointransactionlist()
  }

  selectstatusEvent(item: any) {
    // debugger;
    this.selectedstatus = item.id
    this.getjesssucointransactionlist()
  }

  onremoveuserselection(val: string) {
    this.selecteduser = ''
    this.setSearch = false;
    this.getjesssucointransactionlist()
  }
  onremovetypeselection(val: string) {
    this.selectedtype = ''
    this.getjesssucointransactionlist()
  }
  onremovestatusselection(val: string) {
    this.selectedstatus = ''
    this.getjesssucointransactionlist()
  }

  onFocused(e) {

    // do something when input is focused
  }
  approvetransaction(data) {
    alert(data)
    data.token =  this.admindata[0].token
    this.apiService.approvependingcoin(data)
      .subscribe((response: any[]) => {
        this.success = true
        this.successtext = "Coin added successfully"
        this.pendingtransactiondata
        this.pendingtransactiondata = this.pendingtransactiondata.filter(element => element._id !== data._id);
        setTimeout(() => {
          this.success = false
          this.successtext = "Coin added successfully"
        }, 3000);
      });

  }
  close() {
    this.error = false;
    this.success = false;
  }
}
