import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DxSelectBoxModule } from "devextreme-angular";


// import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
// import { ProductsModule } from './components/products/products.module';
// import { SalesModule } from './components/sales/sales.module';
// import { CouponsModule } from './components/coupons/coupons.module';
import { PagesModule } from './components/pages/pages.module';
import { MediaModule } from './components/media/media.module';
import { MenusModule } from './components/menus/menus.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { UsersModule } from './components/users/users.module';
import { LocalizationModule } from './components/localization/localization.module';
// import { InvoiceModule } from './components/invoice/invoice.module';
import { SettingModule } from './components/setting/setting.module';
// import { ReportsModule } from './components/reports/reports.module';
import { AuthModule } from './components/auth/auth.module';
import { StartpageComponent } from './components/startpage/startpage.component';
import { UserSellerComponent } from './components/user-seller/user-seller.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './components/users/users-routing.module';
import { UsersellerRoutingModule } from './components/user-seller/user-seller-routing.module';
import { UserSellerModule } from './components/user-seller/user-seller.module';
import { SubscriptionComponent } from './components/subscription/subscription.component';
// import { PaymentComponent } from './components/payment/payment.component';
import { WINDOW_PROVIDERS } from './shared/service/windows.service';
// import { PurchaseModule } from './components/purchase/purchase.module';
import { ErrorComponent } from './components/error/error.component';
import { ContactusModule } from './components/contactus/contactus.module';
import { JesssucoinModule } from './components/jesssucoin/jesssucoin.module';
import { FriendsModule } from './components/friends/friends.module';
import { PolicyComponent } from './components/policy/policy.component';
import { SyncproductModule } from './components/syncproduct/syncproduct.module';








@NgModule({
  declarations: [
    AppComponent,
    StartpageComponent,
    SubscriptionComponent,
    // PaymentComponent,
    ErrorComponent,
    PolicyComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    // DashboardModule,
    // InvoiceModule,
    SettingModule,
    // ReportsModule,
    AuthModule,
    UserSellerModule,
    SharedModule,
    LocalizationModule,
    // ProductsModule,
    // SalesModule,
    VendorsModule,
    // CouponsModule,
    PagesModule,
    MediaModule,
    MenusModule,
    UsersModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    // PurchaseModule,
    // DxSelectBoxModule,
    ContactusModule,
    JesssucoinModule,
    FriendsModule,
    SyncproductModule

  ],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
