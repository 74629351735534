import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './jesssucoin-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { JesssucoinComponent } from './jesssucoin.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
@NgModule({
  declarations: [JesssucoinComponent],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    FormsModule,
    NgbModule,
    AutocompleteLibModule
  ]
})
export class JesssucoinModule { }
