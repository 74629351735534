import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubscriptionModel, User, UserModel } from '../../../shared/models/datamodel';
import { ApiService } from '../../../shared/service/api.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public errortext = ""
  public successtext = ""
  public error = false
  public success = false


  public status = false;
  public login_signup_button = true;
  public otp_screen = false;
  public forgot_password_screen = false;
  public resetpassword = false;
  public login_signup_screen_login = true;
  public registrationstep1 = false;
  public registrationstep2 = false;
  public login_signup_screen_signup = false;
  public validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public registrationForm2ndStep: FormGroup;
  public forgotpasswordform: FormGroup;
  public credentialforlogin = new UserModel();
  public credentialforsignup = new UserModel();
  public rememberMe: boolean = false;
  public buttonText: string = "Login";
  public selected = [];
  // public userdata = new UserModel()

  // public role = String;
  public couriorshiprocket = [];
  public user: UserModel;
  // public userdetails: any;
  subscriptiondata: SubscriptionModel;
  public subscriptionstatus = false
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService) {
    // this.userdata = JSON.parse(window.localStorage.getItem("USERDETAILS"))
    this.createLoginForm();
    this.createRegisterForm();
    localStorage.clear();
    this.status = true;
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    })
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: [''],
      password: [''],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
  }

  close() {
    this.error = false;
    this.success = false;
  }

  getuserdetails(id, token) {
    const payload = {
      userid: id,
      token: token
    }
    const userid = id

    this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
      localStorage.setItem("USERDETAILS", JSON.stringify(res));
      this.selected = [];
      this.selected.push(res)
      localStorage.setItem("seller-info", JSON.stringify(this.selected));
    })
  }

  login() {
    if (this.credentialforlogin.email != null) {
      if (this.credentialforlogin.email.match(this.validRegex)) {
        if (this.credentialforlogin.password == null || this.credentialforlogin.password == "") {
          this.errortext = "Please put your password"
          this.error = true
          this.success = false
        }
        else {
          this.apiService.login(this.credentialforlogin).subscribe(
            (user: any) => {
              if (user) {
                this.successtext = "Please Wait..."
                this.success = true
                this.error = false
                this.selected = [];
                this.selected.push(user)
                // localStorage.setItem("seller-info", JSON.stringify(this.selected));
                localStorage.setItem("TOKEN", JSON.stringify(user.token));
                localStorage.setItem("ACCOUNTSTATUS", JSON.stringify(user.accountstatus));
                localStorage.setItem("USERDETAILS", JSON.stringify(user));
                // this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
                // localStorage.setItem("USERDETAILS", JSON.stringify(res));
                // this.selected = [];
                // this.selected.push(res)
                // localStorage.setItem("seller-info", JSON.stringify(this.selected));


                // localStorage.setItem("TOKEN", JSON.stringify(user.token));
                // this.userdetails = user
                let role = user.role;
                // let accountstatus = this.userdetails.accountstatus
                // localStorage.setItem("ACCOUNTSTATUS", JSON.stringify(accountstatus));
                // debugger;
                // if (role == "basic") {
                //   const payload = {
                //     userid: user._id,
                //     token: user.token,
                //     manufacturer: user.manufacturer
                //   }
                //   this.successtext = "Checking subscription details..."
                //   this.error = false
                //   this.success = true
                //   debugger;
                //   this.apiService.getuserSubscriptiondetails(payload).subscribe((res: SubscriptionModel[]) => {
                //     if (res.length > 1) {
                //       this.subscriptiondata = res[0]
                //       let subscriptiondate = new Date(this.subscriptiondata.createdAt)
                //       let subscriptionplan = this.subscriptiondata.subscriptiontype
                //       let planenddate = new Date(new Date().setDate(subscriptiondate.getDate() + 30));
                //       if (planenddate > new Date()) {
                //         localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
                //         localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
                //         this.successtext = "Subscription available"
                //         this.subscriptionstatus = true
                //         this.error = false
                //         this.success = true
                //       } else {
                //         this.errortext = "Subscription ended"
                //         this.subscriptionstatus = false
                //         this.error = true
                //         this.success = false
                //         localStorage.setItem("SUBSCRIPTION", JSON.stringify(false))
                //       }
                //     }
                //     else if (res.length == 1) {
                //       this.subscriptiondata = res[0]
                //       let subscriptionplan = this.subscriptiondata.subscriptiontype
                //       let subscriptiondate = new Date(this.subscriptiondata.createdAt)
                //       let planenddate = new Date(new Date().setDate(subscriptiondate.getDate() + 30));
                //       if (planenddate > new Date()) {
                //         localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
                //         localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
                //         this.successtext = "Subscription available"
                //         this.error = false
                //         this.success = true
                //         this.subscriptionstatus = true
                //       } else {
                //         this.errortext = "Subscription ended"
                //         this.error = true
                //         this.success = false
                //         this.subscriptionstatus = false
                //         localStorage.setItem("SUBSCRIPTION", JSON.stringify(false))
                //       }
                //     }
                //     else {
                //       this.errortext = "Subscription not available"
                //       this.error = true
                //       this.success = false
                //       localStorage.setItem("SUBSCRIPTION", JSON.stringify(false))
                //     }
                //     let manufacturer = user.manufacturer
                //     this.selected = [];
                //     this.selected.push(user)
                //     localStorage.setItem("seller-info", JSON.stringify(this.selected));
                //     if (manufacturer == null || manufacturer == undefined || manufacturer == "") {
                //       if (user.gst_number == null || user.gst_number == undefined || user.gst_number == "") {
                //         localStorage.setItem("GST", JSON.stringify(false))
                //         localStorage.setItem("MANUFACTURER", JSON.stringify(false))
                //         this.router.navigateByUrl("/auth/gstupload")
                //       }
                //       else {
                //         localStorage.setItem("GST", JSON.stringify(true))
                //         localStorage.setItem("MANUFACTURER", JSON.stringify(false))
                //         this.router.navigateByUrl("/auth/gstupload")
                //       }
                //     }
                //     else if (user.gst_number == null || user.gst_number == undefined || user.gst_number == "") {
                //       localStorage.setItem("GST", JSON.stringify(false))
                //       // this.router.navigateByUrl("/auth/gstupload")
                //       localStorage.setItem("MANUFACTURER", JSON.stringify(true))
                //       this.router.navigateByUrl("/auth/gstupload")
                //     }
                //     else {
                //       localStorage.setItem("GST", JSON.stringify(true))
                //       localStorage.setItem("MANUFACTURER", JSON.stringify(true))
                //       if (this.subscriptionstatus == true) {
                //         this.router.navigateByUrl("/dashboard/default")
                //       }
                //       else {
                //         this.router.navigateByUrl("/subscription-selection")
                //       }
                //     }
                //   })
                // }
                if (role == "admin") {
                  this.selected = [];
                  this.selected.push(user)
                  localStorage.setItem("admin-info", JSON.stringify(this.selected));
                  localStorage.setItem("ADMIN", JSON.stringify(true))
                  this.router.navigateByUrl("/all/users-sellers");
                }
                else{
                  this.errortext = "For Admin only"
                  this.error = true
                  this.success = false
                }
              }
              //}
              // )
            },
            (err) => {
              // console.error(err);
              if (err.status == 402) {
                this.errortext = "You still not set your password. Please check your mail for OTP and set your password for login"
                this.error = true
                this.success = false;
                this.credentialforsignup.email = this.credentialforlogin.email
                this.login_signup_button = false;
                this.otp_screen = true;
                this.forgot_password_screen = false;
                this.resetpassword = false;
                this.login_signup_screen_login = false;
                this.registrationstep1 = false;
                this.registrationstep2 = true;
                this.login_signup_screen_signup = false;
              }
              else if (err.status == 400) {
                this.errortext = "Invalid Credentials"
                this.error = true
                this.success = false
              }
              else if (err.status == 403) {
                this.errortext = "user not verified"
                this.error = true
                this.success = false
              }
            }
          );
        }
      }
      else {
        this.errortext = "Please put valid email"
        this.error = true
        this.success = false

      }
    }
    else {
      this.errortext = "please submit email"
      this.error = true
      this.success = false
    }


  }



  Step() {
    if (this.login_signup_screen_login == true) {
      this.login_signup_button = true;
      this.otp_screen = false;
      this.forgot_password_screen = false;
      this.resetpassword = false;
      this.login_signup_screen_login = false;
      this.registrationstep1 = false;
      this.registrationstep2 = false;
      this.login_signup_screen_signup = true;
    } else {
      this.login_signup_button = true;
      this.otp_screen = false;
      this.forgot_password_screen = false;
      this.resetpassword = false;
      this.login_signup_screen_login = true;
      this.registrationstep1 = false;
      this.registrationstep2 = false;
      this.login_signup_screen_signup = false;
    }
  }
  register() {
    if (this.credentialforsignup.COO != null) {
      if (this.credentialforsignup.email != null) {
        if (this.credentialforsignup.email.match(this.validRegex)) {
          console.log(this.credentialforsignup)
          if (this.credentialforsignup.firstname == null) {
            this.errortext = "Please put your firstname"
            this.error = true
            this.success = false
          }
          else if (this.credentialforsignup.lastname == null) {
            this.errortext = "Please put your lastname"
            this.error = true
            this.success = false
          }
          else if (this.credentialforsignup.phone == null) {
            this.errortext = "Please put your phone number"
            this.error = true
            this.success = false
          }
          else {
            this.apiService.register(this.credentialforsignup).subscribe(
              (user) => {
                this.error = false
                this.success = true
                this.successtext = "Registration successful. Please submit OTP that has been sent to your email"
                this.login_signup_button = false;
                this.otp_screen = true;
                this.forgot_password_screen = false;
                this.resetpassword = false;
                this.login_signup_screen_login = false;
                this.registrationstep1 = false;
                this.registrationstep2 = true;
                this.login_signup_screen_signup = false;
              },
              // ***
              (err) => {
                this.errortext = err.error
                this.error = true
                this.success = false;
                this.credentialforsignup.email = this.credentialforlogin.email
                this.login_signup_button = false;
                this.otp_screen = true;
                this.forgot_password_screen = false;
                this.resetpassword = false;
                this.login_signup_screen_login = false;
                this.registrationstep1 = false;
                this.registrationstep2 = true;
                this.login_signup_screen_signup = false;
              })
          }
        }
        else {
          this.errortext = "Please put your valid email"
          this.error = true
          this.success = false
        }
      }
      else {
        this.errortext = "Please submit email"
        this.error = true
        this.success = false
      }
    }
    else {
      this.errortext = "Please select country of origin"
      this.error = true
      this.success = false
    }
  }

  setPassword() {
    if (this.credentialforsignup.otp == null) {
      this.errortext = "Please submit OTP received on your email or phone"
      this.error = true
      this.success = false

    }
    else if (this.credentialforsignup.password == null) {
      this.errortext = "Please set your password"
      this.error = true
      this.success = false
    }
    else if (this.credentialforsignup.newPassword == null) {
      this.errortext = "Please confirm password"
      this.error = true
      this.success = false
    }
    else {
      if (this.credentialforsignup.password == this.credentialforsignup.newPassword) {
        this.apiService.setpassword(this.credentialforsignup).subscribe(
          (user) => {
            console.log(user)
            this.success = true
            this.error = false
            this.successtext = "Registration successful. Please login"

            this.login_signup_button = true;
            this.otp_screen = false;
            this.forgot_password_screen = false;
            this.resetpassword = false;
            this.login_signup_screen_login = true;
            this.registrationstep1 = false;
            this.registrationstep2 = false;
            this.login_signup_screen_signup = false;
            this.credentialforsignup = new UserModel();
          }, (err) => {
            this.error = true
            this.success = false
            this.errortext = err.error
          })
      }
      else {
        this.errortext = "Password not matched"
        this.error = true
        this.success = false
      }
    }
  }

  forgotpassword() {
    this.credentialforsignup = new UserModel();
    this.credentialforsignup.email = "";
    this.forgot_password_screen = true;
    this.login_signup_screen_login = false;
    this.login_signup_screen_signup = false;
    this.registrationstep1 = false;
    this.registrationstep2 = false;
    this.login_signup_button = false;
    this.otp_screen = false;
  }
  backtologin() {
    this.credentialforlogin = new UserModel();
    this.forgot_password_screen = false;
    this.login_signup_screen_login = true;
    this.login_signup_screen_signup = false;
    this.registrationstep1 = false;
    this.registrationstep2 = false;
    this.login_signup_button = true;
    this.otp_screen = false;
  }

  forgotPasswordfunction() {
    if (this.credentialforsignup.email == "" && this.credentialforsignup.phone == null) {
      this.errortext = "Please submit your registered email or phone"
      this.error = true
      this.success = false
    }
    else {
      this.apiService.forgotpassword(this.credentialforsignup).subscribe((res) => {

        this.successtext = "OTP has been send to your registered email"
        this.error = false
        this.success = true
        this.login_signup_button = false;
        this.forgot_password_screen = false;
        this.otp_screen = false;
        this.resetpassword = true;
        this.registrationstep1 = false;
      },
        (err) => {
          if (err.status == 409) {
            this.errortext = "Invalid Credentials/User is not active"
            this.error = true
            this.success = false

          }
          else if (err.status == 400) {
            this.errortext = "User with that email or phone does not exist"
            this.error = true
            this.success = false
          }
          else if (err.status == 403) {
            this.errortext = "User not verified."
            this.error = true
            this.success = false

          }
        })
    }
  }

  resetPassword() {
    if (this.credentialforsignup.otp == null) {
      this.errortext = "Please submit OTP received on your email or phone."
      this.error = true
      this.success = false
    }
    else if (this.credentialforsignup.password == null) {
      this.errortext = "Please set your password"
      this.error = true
      this.success = false
    }
    else if (this.credentialforsignup.newPassword == null) {
      this.errortext = "Please confirm password"
      this.error = true
      this.success = false
    }
    else {
      if (this.credentialforsignup.password == this.credentialforsignup.newPassword) {
        this.apiService.resetpassword(this.credentialforsignup).subscribe(
          (user) => {
            this.login_signup_screen_login = true;
            this.login_signup_screen_signup = false;
            this.registrationstep1 = false;
            this.registrationstep2 = false;
            this.login_signup_button = true;
            this.otp_screen = false;
            this.resetpassword = false;
            this.credentialforsignup = new UserModel();
          })
      }
      else {
        this.errortext = "Password not matched"
        this.error = true
        this.success = false
      }
    }
  }
}
