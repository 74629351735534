import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandModel } from 'src/app/shared/models/datamodel';
import { ApiService } from '../../../../shared/service/api.service';
import { ProductimageService } from '../../../../shared/service/fileupload.service';


@Component({
  selector: 'app-brand-edit',
  templateUrl: './brand-edit.component.html',
  styleUrls: ['./brand-edit.component.scss']
})
export class BrandEditComponent implements OnInit {
  [x: string]: any;
  public text: String
  public sellerdata: any;
  public admindata = [];
  public Brandname = String;
  public brandlist: any;
  public id: any;
  public brandname: any;
  public status: Boolean;
  public new_image_url: any;
  public payloadforimageupdate: any;
  public updatesinglebrand: any;
  public brandUpdate: any;
  public videourls: any = [];
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public permission: any;
  selectedFiles: FileList;
  currentFileUpload: File;
  FileUploadStatus: Boolean;
  public popupshow: boolean= false;
  public popupboxvalue: String;
  BrandModel = new BrandModel();

  constructor(private apiService: ApiService, private router: Router, private productimageService: ProductimageService) { }

  ngOnInit(): void {
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.getbranddetails();
  }

  getbranddetails() {
    this.Brandname = JSON.parse(window.localStorage.getItem("brandname"));
    console.log(this.Brandname)
    var payload = {
      "brandname": this.Brandname,
      "token": this.admindata[0].token
    }
    this.apiService.getbranddeatils(payload)
      .subscribe((res) => {
        this.brandlist = res;
        if (res != null) {
          var keywords = this.brandlist[0].keywords;
          this.BrandModel.external_keywords = keywords;
          this.fieldArray = [];
          var lengthOfVideoLinkres = this.brandlist[0].videolink.length;
          let arr: any = [];
          let brandlistarray: any = [];
          for (var i = 0; i < lengthOfVideoLinkres; i++) {
            this.newAttribute.code = this.brandlist[0].videolink[i];
            this.fieldArray.push(this.newAttribute)
          }
        }
      })
  }

  upload(type) {
    this.FileUploadStatus = true;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.productimageService.pushFileToStorage(this.currentFileUpload).subscribe(res => {
      this.FileUploadStatus = false;
      if (type == 'main') {
        this.brandlist[0].branddetails[0].image = res.toString();
      }
    });
  }

  selectFile(event, type) {
    this.selectedFiles = event.target.files;
    this.upload(type);
  }

  onSubmit(data) {
    if (this.FileUploadStatus == true) {
      this.popupshow = true;
      this.popupboxvalue= "File is uploading. Please wait";
    }
    else {
      console.log(data);
      const payloadforimageupdate = {
        "id": data._id,
        "imageurl": this.brandlist[0].branddetails[0].image,
        "token": this.admindata[0].token
      }
      console.log(payloadforimageupdate)
      this.productimageService.updatebrandimage(payloadforimageupdate)
        .subscribe(re => {
          this.popupshow = true;
          this.popupboxvalue= "Brand has been Updated successfully.";
         
        },
        )
    }
  }

  onSubmit2(brandUpdate) {
    var keywordsofbrandupdate = brandUpdate._directives[0].model;
    var lengthofdirectives = brandUpdate._directives.length;
    this.videourls = [];
    for (var index = 1; index < lengthofdirectives; index++) {
      this.videourls = this.videourls.concat(brandUpdate._directives[index].model);
    }
    console.log(this.videourls);
    this.newAttribute.code = this.BrandModel.external_video_url;
    if (this.BrandModel.external_keywords == null) {
      alert("Please add any keywords")
    }
    else {
      const payload = {
        "id": this.brandlist[0].branddetails[0]._id,
        "keywords": keywordsofbrandupdate,
        "videolink": this.videourls,
        "token": this.admindata[0].token
      }

      this.apiService.updatesinglebranddetails(payload)
        .subscribe((res: any) => {
          if (res != null) {
            this.status = true;
            if (this.status == true) {
              this.text = "Updated"
            }
            this.popupshow = true;
            this.popupboxvalue= `Brand details has been ${this.text}.`;
          }
        })
    }
  }

  onconfirm(){

    if (this.popupboxvalue == `Brand details has been ${this.text}.`) {
      this.popupshow = false;
      this.router.navigate(['/media/brands']);
    }
    else if (this.popupboxvalue == "Brand has been Updated successfully."){
        
      this.popupshow = false;
      this.router.navigate(['/media/brands'])
      
    }
    else{
      this.popupshow = false;
    }

  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute)
    this.newAttribute = {};
  }

  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }
  logValue() {
    console.log(this.fieldArray);
  }

}
