<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Add Page</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form [formGroup]="generalForm"  class="needs-validation" novalidate="">
                            <h4>General</h4>
                            <div class="form-group row">
                                <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span> Name</label>
                                <input class="form-control col-xl-8 col-md-7" formControlName="name"
                                    id="validationCustom0" type="text" required="">
                            </div>
                            <div class="form-group row editor-label">
                                <label class="col-xl-3 col-md-4"><span>*</span> Description</label>
                                <div class="col-xl-8 col-md-7 editor-space">
                                    <ck-editor id="editor1" name="editor1" cols="30" rows="10" language="en"
                                        [fullPage]="false"></ck-editor>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Status</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input id="checkbox-primary-2" formControlName="status" type="checkbox"
                                        data-original-title="" title="">
                                    <label for="checkbox-primary-2">Enable the Page</label>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
                <ngb-tab>
                    <ng-template ngbTabTitle>SEO</ng-template>
                    <ng-template ngbTabContent>
                        <form [formGroup]="seoForm" class="needs-validation" novalidate="">
                            <h4>SEO</h4>
                            <div class="form-group row">
                                <label for="validationCustom2" class="col-xl-3 col-md-4">Meta Title</label>
                                <input class="form-control col-xl-8 col-md-7" formControlName="title"
                                    id="validationCustom2" type="text" required="">
                            </div>
                            <div class="form-group row editor-label">
                                <label class="col-xl-3 col-md-4">Meta Description</label>
                                <textarea rows="4" formControlName="meta_desc" class="col-xl-8 col-md-7"></textarea>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
            <div class="pull-right">
                <button type="button" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->