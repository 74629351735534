import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/service/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  public contactus_list = [];
  user: any;
  Id: any;
  public offset: number = 0;
  public count: number = 20;
  public admindata = []
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"));
    this.getAllcontactuslist()
  }

  getAllcontactuslist() {
    this.apiService.getcontactusdata(this.offset, this.count, this.admindata[0].token)
      .subscribe((list: any[]) => {
        if (this.contactus_list.length > 0) {
          this.contactus_list = this.contactus_list.concat(list)
        }
        else {
          this.contactus_list = list
        }
      });
    // alert("User Customer List");

  }
  onScroll() {
    this.offset++;
    this.getAllcontactuslist();
  }

}
