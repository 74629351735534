import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	image?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMSFORADMIN: Menu[] = [
		// {
		// 	path: '/dashboard/default', title: 'Dashboard', icon: 'home', image: 'assets/images/dashboard/user-icons/home.png', type: 'link', badgeType: 'primary', active: false
		// },
		{
			path: '/all/users-sellers', title: 'User-Seller List', icon: 'home', type: 'link', badgeType: 'primary', active: false, image: 'assets/images/dashboard/user-icons/home.png'
		},
		{
			path: '/syncproduct', title: 'Sync Product', icon: 'user-plus', type: 'link', badgeType: 'primary', active: false, image: 'assets/images/dashboard/user-icons/product.png'
		},
		{
			path: '/jesssucoin', title: 'Jesssu-Coin Activity', icon: 'dollar-sign', type: 'link', badgeType: 'primary', active: false, image: 'assets/images/dashboard/user-icons/coin.png'
		},

		{
			path: '/media/brands', title: 'Jesssu-Brands', icon: 'dollar-sign', type: 'link', badgeType: 'primary', active: false, image: 'assets/images/dashboard/user-icons/brands.png'
		},
		{
			path: '/products/physical/category', title: 'Jesssu-Categories', icon: 'dollar-sign', type: 'link', badgeType: 'primary', active: false, image: 'assets/images/dashboard/user-icons/product.png'
		},
		// {
		// 	title: 'Products', icon: 'box', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'Physical', type: 'sub', children: [
		// 				{ path: '/products/physical/category', title: 'Category', type: 'link' },
		// 				// { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
		// 				{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
		// 				// { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
		// 				{ path: '/products/physical/add-product/0', title: 'Add Product', type: 'link' },
		// 				{ path: '/products/physical/product-import-export', title: 'Import/Export', type: 'link' }
		// 			]
		// 		},
		// 		// {
		// 		// 	title: 'digital', type: 'sub', children: [
		// 		// 		{ path: '/products/digital/digital-category', title: 'Category', type: 'link' },
		// 		// 		{ path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
		// 		// 		{ path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
		// 		// 		{ path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
		// 		// 	]
		// 		// },
		// 	]
		// },
		// {
		// 	path: '/jesssucoin', title: 'Jesssu coin', icon: 'user-plus', type: 'link', badgeType: 'primary', active: false
		// },
		{
			path: '/contactus', title: 'Contact us', icon: 'user-plus', type: 'link', badgeType: 'primary', active: false, image: 'assets/images/dashboard/user-icons/referral.png'
		}
		// {
		// 	title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
		// 		// { path: '/sales/orders', title: 'Orders', type: 'link' },
		// 		// { path: '/sales/transactions', title: 'Transactions', type: 'link' },
		// 		{ path: '/sales/sale-data', title: 'Sale-Data', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Sale-Order', icon: 'dollar-sign', type: 'sub', active: false, children: [
		// 		{ path: '/sales/saleorders', title: 'Sale-Orders', type: 'link' },
		// 		{ path: '/sales/saleorders/addsaleorder/0', title: 'Add-Sale-Order', type: 'link' },
		// 	]
		// },

		// {
		// 	title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
		// 		{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
		// 		{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
		// 		{ path: '/pages/list-page', title: 'List Page', type: 'link' },
		// 		{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
		// 	]
		// },

		// {
		// 	title: 'Brand', icon: 'camera', type: 'sub', active: false, image: 'assets/images/dashboard/user-icons/brands.png', children: [
		// 		// { path: '/media', title: 'Media', type: 'link' },
		// 		{ path: '/media/brands', title: 'Brands', type: 'link' },
		// 		{ path: '/products/physical/category', title: 'Category', type: 'link' },
		// 	]
		// },

		// {
		// 	title: 'Menus', icon: 'align-left', type: 'sub', active: false, children: [
		// 		{ path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
		// 		{ path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
		// 	]
		// },

		// {
		// 	title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
		// 		{ path: '/users/list-user', title: 'User List', type: 'link' },
		// 		{ path: '/users/create-user', title: 'Create User', type: 'link' },
		// 	]
		// },
		// {
		// 	path: '/friends', title: 'Friends', icon: 'user-plus', type: 'link', badgeType: 'primary', active: false,  image: 'assets/images/dashboard/user-icons/friend.png'
		// },

		// {
		// 	title: 'Customer', icon: 'user-plus', type: 'sub', active: false, children: [
		// 		{ path: '/customer', title: 'Customer List', type: 'link' },
		// 		{ path: '/customer/add-customer/0', title: 'Add Customer', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Purchase', icon: 'user-plus', type: 'sub', active: false, children: [
		// 		{ path: '/purchase', title: 'Purchase List', type: 'link' },
		// 		{ path: '/purchase/add-purchase/0', title: 'Add Purchase', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Supplier', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/supplier', title: 'Supplier List', type: 'link' },
		// 		{ path: '/supplier/add-supplier/0', title: 'Add Supplier', type: 'link' },
		// 	]
		// },

		// {
		// 	title: 'Vendors', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
		// 		{ path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Localization', icon: 'chrome', type: 'sub', children: [
		// 		{ path: '/localization/translations', title: 'Translations', type: 'link' },
		// 		{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
		// 		{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		// },
		// {
		// 	title: 'Settings', icon: 'settings', type: 'sub', children: [
		// 		{ path: '/settings/profile', title: 'Profile', type: 'link' },
		// 		{ path: '/subscription-selection', title: 'Change Plan', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Invoice', path: '/invoice', icon: 'archive', type: 'link', active: false
		// },
		// {
		// 	title: 'Login',path: '/auth/login', icon: 'log-in', type: 'link', active: false
		// }
	]
	MENUITEMSFORBASICENTERPRISE: Menu[] = [
		{
			image: 'assets/images/dashboard/user-icons/home.png', path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			image: 'assets/images/dashboard/user-icons/user.png',
			title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users/list-user', title: 'User List', type: 'link' },
				{ path: '/users/create-user', title: 'Create User', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/product.png',
			path: '/products/physical/product-list',
			title: 'Products', icon: 'box', type: 'link', active: false,
			//  children: [
			// 	{
			// 		active: true, children: [
			// 			{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
			// 			{ path: '/products/physical/add-product/0', title: 'Add Product', type: 'link' },
			// 			{ path: '/products/physical/product-import-export', title: 'Import/Export', type: 'link' }
			// 		]
			// 	}
			// ]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Customer', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/customer', title: 'Customer List', type: 'link' },
				{ path: '/customer/add-customer/0', title: 'Add Customer', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Supplier', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/supplier', title: 'Supplier List', type: 'link' },
				{ path: '/supplier/add-supplier/0', title: 'Add Supplier', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/sales.png',
			title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/sale-data', title: 'Sale-Data', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/sale.png',
			title: 'Sale-Order', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/saleorders', title: 'Sale-Orders', type: 'link' },
				{ path: '/sales/saleorders/addsaleorder/0', title: 'Add-Sale-Order', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/coin.png',
			title: 'Purchase', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/purchase', title: 'Purchase List', type: 'link' },
				{ path: '/purchase/purchase-order', title: 'Purchase Orders', type: 'link' },
				{ path: '/purchase/add-purchase-order/0', title: 'Add Purchase Order', type: 'link' },
			]
		},

		{
			image: 'assets/images/dashboard/user-icons/contact-us.png',
			title: 'Settings', icon: 'settings', type: 'sub', children: [
				{ path: '/settings/profile', title: 'Profile', type: 'link' },
				{ path: '/subscription-selection', title: 'Change Plan', type: 'link' }
			]
		}
	]
	MENUITEMSFORBASICPROFESSIONAL: Menu[] = [
		{
			image: 'assets/images/dashboard/user-icons/home.png', path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			image: 'assets/images/dashboard/user-icons/user.png',
			title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users/list-user', title: 'User List', type: 'link' },
				{ path: '/users/create-user', title: 'Create User', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/product.png',
			path: '/products/physical/product-list',
			title: 'Products', icon: 'box', type: 'link', active: false,
			//  children: [
			// 	{
			// 		active: true, children: [
			// 			{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
			// 			{ path: '/products/physical/add-product/0', title: 'Add Product', type: 'link' },
			// 			{ path: '/products/physical/product-import-export', title: 'Import/Export', type: 'link' }
			// 		]
			// 	}
			// ]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Customer', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/customer', title: 'Customer List', type: 'link' },
				{ path: '/customer/add-customer/0', title: 'Add Customer', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Supplier', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/supplier', title: 'Supplier List', type: 'link' },
				{ path: '/supplier/add-supplier/0', title: 'Add Supplier', type: 'link' },
			]
		},

		{
			image: 'assets/images/dashboard/user-icons/sales.png',
			title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/sale-data', title: 'Sale-Data', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/sales.png',
			title: 'Sale-Order', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/saleorders', title: 'Sale-Orders', type: 'link' },
				{ path: '/sales/saleorders/addsaleorder/0', title: 'Add-Sale-Order', type: 'link' },
			]
		},


		{
			image: 'assets/images/dashboard/user-icons/coin.png',
			title: 'Purchase', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/purchase', title: 'Purchase List', type: 'link' },
				{ path: '/purchase/add-purchase/0', title: 'Add Purchase', type: 'link' },
			]
		},

		{
			image: 'assets/images/dashboard/user-icons/contact-us.png',
			title: 'Settings', icon: 'settings', type: 'sub', children: [
				{ path: '/settings/profile', title: 'Profile', type: 'link' },
				{ path: '/subscription-selection', title: 'Change Plan', type: 'link' },
			]
		},

	]
	MENUITEMSFORBASICSTARTER: Menu[] = [
		{
			image: 'assets/images/dashboard/user-icons/home.png', path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			image: 'assets/images/dashboard/user-icons/product.png',
			path: '/products/physical/product-list',
			title: 'Products', icon: 'box', type: 'link', active: false,
			//  children: [
			// 	{
			// 		active: true, children: [
			// 			{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
			// 			{ path: '/products/physical/add-product/0', title: 'Add Product', type: 'link' },
			// 			{ path: '/products/physical/product-import-export', title: 'Import/Export', type: 'link' }
			// 		]
			// 	}
			// ]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Customer', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/customer', title: 'Customer List', type: 'link' },
				{ path: '/customer/add-customer/0', title: 'Add Customer', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Supplier', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/supplier', title: 'Supplier List', type: 'link' },
				{ path: '/supplier/add-supplier/0', title: 'Add Supplier', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/sales.png',
			title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/sale-data', title: 'Sale-Data', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/sales.png',
			title: 'Sale-Order', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/saleorders', title: 'Sale-Orders', type: 'link' },
				{ path: '/sales/saleorders/addsaleorder/0', title: 'Add-Sale-Order', type: 'link' },
			]
		},

		{
			image: 'assets/images/dashboard/user-icons/coin.png',
			title: 'Purchase', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/purchase', title: 'Purchase List', type: 'link' },
				{ path: '/purchase/add-purchase/0', title: 'Add Purchase', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/contact-us.png',
			title: 'Settings', icon: 'settings', type: 'sub', children: [
				{ path: '/settings/profile', title: 'Profile', type: 'link' },
				{ path: '/subscription-selection', title: 'Change Plan', type: 'link' },
			]
		}
	]
	MENUITEMSFORBASICFREE: Menu[] = [
		{
			image: 'assets/images/dashboard/user-icons/home.png', path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			image: 'assets/images/dashboard/user-icons/product.png',
			path: '/products/physical/product-list',
			title: 'Products', icon: 'box', type: 'link', active: false,
			//  children: [
			// 	{
			// 		active: true, children: [
			// 			{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
			// 			{ path: '/products/physical/add-product/0', title: 'Add Product', type: 'link' },
			// 		]
			// 	}
			// ]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Customer', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/customer', title: 'Customer List', type: 'link' },
				{ path: '/customer/add-customer/0', title: 'Add Customer', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/customer.png',
			title: 'Supplier', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/supplier', title: 'Supplier List', type: 'link' },
				{ path: '/supplier/add-supplier/0', title: 'Add Supplier', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/sale.png',
			title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/sale-data', title: 'Sale-Data', type: 'link' },
			]
		},
		{
			image: 'assets/images/dashboard/user-icons/sale.png',
			title: 'Sale-Order', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/saleorders', title: 'Sale-Orders', type: 'link' },
				{ path: '/sales/saleorders/addsaleorder/0', title: 'Add-Sale-Order', type: 'link' },
			]
		},

		{
			image: 'assets/images/dashboard/user-icons/contact-us.png',
			title: 'Settings', icon: 'settings', type: 'sub', children: [
				{ path: '/settings/profile', title: 'Profile', type: 'link' },
				{ path: '/subscription-selection', title: 'Change Plan', type: 'link' },
			]
		}
	]

	itemsforadmin = new BehaviorSubject<Menu[]>(this.MENUITEMSFORADMIN);
	itemsforbasicfree = new BehaviorSubject<Menu[]>(this.MENUITEMSFORBASICFREE);
	itemsforbasicstarter = new BehaviorSubject<Menu[]>(this.MENUITEMSFORBASICSTARTER);
	itemsforbasicprofessional = new BehaviorSubject<Menu[]>(this.MENUITEMSFORBASICPROFESSIONAL);
	itemsforbasicenterprise = new BehaviorSubject<Menu[]>(this.MENUITEMSFORBASICENTERPRISE);

}
