import { Component, HostListener, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/service/api.service';
import { io, Socket } from 'socket.io-client';
// const SERVER_URL = 'http://localhost:4001'; // Update with your server URL
const SERVER_URL = 'https://scrapper.jesssu.com'; // Update with your server URL

@Component({
  selector: 'app-syncproduct',
  templateUrl: './syncproduct.component.html',
  styleUrls: ['./syncproduct.component.scss'],
})
export class SyncproductComponent implements OnInit {
  private socket: Socket;
  public sellerdata: any;
  public admindata: any;
  public userdata: any;
  public token: any;
  public pageview = true;
  errortext: string = '';
  successtext: string = '';
  error: boolean = false;
  success: boolean = false;

  selectedChannel: string = '--Select a channel --';
  selectedStatus: boolean = false;
  channelURL: string = '';
  inputText: string = '';
  textArray: string[] = [];
  selectedItems: string[] = [];
  auditData: any[] = [];
  categories: any[] = [];
  public subcategorybox: Boolean = false;
  public categorybox: Boolean = true;
  public genderbox: Boolean = false;

  Primary: string = '';
  InputPrimary: string = '';
  Subcategories: string[] = [];
  Categoryid: string = '';
  Secondary: string = "";
  InputSecondary: string = "";
  Gender: string = '';
  channelList = [];
  keywords: any[] = []
  URLs: any[] = []
  addSection: Boolean = false
  selectedChannelData: any = {}
  addEditType: String = ''
  addEditIndex: number = 0;
  keywordURL: String = ''
  constructor(private apiService: ApiService, public router: Router, private cdr: ChangeDetectorRef) {
    this.sellerdata = JSON.parse(window.localStorage.getItem('seller-info'));
    this.admindata = JSON.parse(window.localStorage.getItem('admin-info'));
    this.token = JSON.parse(window.localStorage.getItem('TOKEN'));
    if (this.admindata == null) {
      this.router.navigate(['/page-not-found']);
    }
  }

  ngOnInit(): void {
    // this.socket = io(SERVER_URL);
    this.socket = io(SERVER_URL, {
      // Specify CORS options
      transports: ['websocket'],
      withCredentials: true, // Allow credentials (cookies) to be sent cross-origin
      extraHeaders: {
        'Access-Control-Allow-Origin': 'https://admin.jesssu.com', // Set the allowed origin
      }
    });
    this.getChannels();
    this.getAllAuditData();
    this.getCategories();
    // Listen for scrape status updates
    this.socket.on('scrapeStatus', (data) => {
      console.log('Scrape status:', data.status);
      this.success = true;
      this.successtext = data.status;
      // Update your Angular component with the received status
      // You can use a service to communicate between components or update the UI directly
    });
  }
  ngOnDestroy() {
    // Disconnect the socket when the component is destroyed
    this.socket.disconnect();
  }
  getCategories() {
    let payload = {
      token: this.admindata[0].token
    };
    this.apiService.getCategoryList(payload, 0, 1000).subscribe((res: []) => {
      this.categories = res;
    });
  }

  getChannels() {
    this.apiService.getChannels(this.token).subscribe((res: []) => {
      this.channelList = res;
    });
  }

  openCloseBox(type: string, data: any, index: number, addtype: String) {
    debugger;
    this.addEditType = type
    this.addEditIndex = index
    this.keywordURL = addtype
    if (type === 'add') {
      this.inputText = ''
      this.selectedStatus = true
      this.InputPrimary = ''
      this.InputSecondary = ''
      this.Gender = ''
      this.cdr.detectChanges();
      this.addSection = !this.addSection
    }
    else if (type === 'edit') {
      this.inputText = data?.keyword
      this.selectedStatus = data?.sync
      this.InputPrimary = data?.Primary
      this.InputSecondary = data?.Secondary
      this.Gender = data?.Gender
      this.cdr.detectChanges(); // Manually trigger change detection
      this.addSection = true
    };
  }

  async addKeyword() {
    this.closealert();
    let result = await this.verifyInput();
    if (result === true) {
      let token = this.token

      let finalData = null
      if (this.keywordURL === "keyword") {
        finalData = {
          keyword: this.inputText,
          sync: this.selectedStatus,
          Primary: this.InputPrimary,
          Secondary: this.InputSecondary,
          Gender: this.Gender,
        };
      }
      else {
        finalData = {
          URL: this.inputText,
          sync: this.selectedStatus,
          Primary: this.InputPrimary,
          Secondary: this.InputSecondary,
          Gender: this.Gender,
        };
      }
      if (this.addEditType === "add") {
        if (this.keywordURL === "URL") {
          this.URLs.push(finalData)
        }
        else {
          this.keywords.push(finalData)
        }
      }
      else if (this.addEditType === "edit") {
        if (this.keywordURL === "URL") {
          this.URLs[this.addEditIndex] = finalData
        }
        else {
          this.keywords[this.addEditIndex] = finalData
        }
      }

      if (this.keywordURL === "URL") {
        const payload = {
          "keywords": this.URLs,
          "channel": this.selectedChannelData.channelName,
          "token": this.token
        }
        this.apiService.updateChannelURLS(payload).subscribe((res: any) => {
          this.addSection = false
        });
      } else {
        const payload = {
          "keywords": this.keywords,
          "channel": this.selectedChannelData.channelName,
          "token": this.token
        }
        this.apiService.updateChannelKeywords(payload).subscribe((res: any) => {
          this.addSection = false
        });
      }
    }
  }

  deleteKeywordURL(index: number, type: string) {
    if (type === "URL") {
      this.URLs.splice(index, 1);
      const payload = {
        "keywords": this.URLs,
        "channel": this.selectedChannel,
        "token": this.token
      }
      this.apiService.updateChannelURLS(payload).subscribe((res: any) => {
        this.addSection = false
      });
    } else {
      this.keywords.splice(index, 1);
      const payload = {
        "keywords": this.keywords,
        "channel": this.selectedChannel,
        "token": this.token
      }
      this.apiService.updateChannelKeywords(payload).subscribe((res: any) => {
        this.addSection = false
      });
    }

  }
  selectChange() {
    this.subcategorybox = true;
    this.genderbox = true;
    this.categories.forEach((i: any) => {
      if (i.categoryname == this.Primary) {
        this.Primary = i.categoryname;
        this.InputPrimary = i.categoryname;
        this.Categoryid = i._id;
      }
    });
    var payload = {
      id: this.Categoryid,
      token: this.admindata[0].token
    };
    this.apiService.getSubcategory(payload).subscribe((res: []) => {
      this.Subcategories = res;
      this.subcategorybox = true;
    });
  }

  selectsubChange() {
    this.InputSecondary = this.Secondary;
    console.log(this.Secondary);
  }

  // Handle the event when a new item is added
  onChannelSelect(event: any) {
    debugger;
    this.closealert();
    this.selectedChannel = event.target.value;
    const currentSelectedChannel = this.channelList.find(sel => sel.channelName === event.target.value);
    this.selectedChannelData = currentSelectedChannel
    this.channelURL = this.selectedChannelData.mainURL
    this.keywords = this.selectedChannelData?.keywords || [];
    this.URLs = this.selectedChannelData?.URLs || []
  }

  onGenderSelect(event: any) {
    this.closealert();
    this.Gender = event.target.value;
  }

  onStatusSelect(event: any) {
    this.closealert();
    this.selectedStatus = event.target.value;
    console.log('Selected Status:', this.selectedStatus);
  }
  selectFile(event, type) { }
  addTextToList() {
    this.closealert();
    if (this.inputText.trim() !== '') {
      this.textArray.push(this.inputText.trim());
      this.inputText = ''; // Clear the input after adding to the array
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent) {
    // Trigger the addTextToList function on Enter key press
    this.addTextToList();
  }

  removeTextFromList(text: string) {
    this.textArray = this.textArray.filter((item) => item !== text);
  }
  onURLChange() {
    this.closealert();
    console.log('Entered Channel URL:', this.channelURL);
  }
  onCategoryChange() {
    this.subcategorybox = true;
    this.genderbox = true;
    let status: Boolean = false;
    this.Primary = this.InputPrimary;
    this.categories.forEach((i: any) => {
      if (i.categoryname == this.Primary) {
        this.Primary = i.categoryname;
        this.Categoryid = i._id;
        status = true;
      }
    });

    if (status === true) {
      var payload = {
        id: this.Categoryid,
        token: this.admindata[0].token
      };
      this.apiService.getSubcategory(payload).subscribe((res: []) => {
        this.Subcategories = res;
      });
    }
  }
  onSubCategoryChange() {
    this.Secondary = this.InputSecondary;
  }


  getAllAuditData() {
    const payload = {
      useremail: this.admindata[0].email,
      token: this.token
    };
    this.apiService.getsellerscrapeData(payload).subscribe((result: any[]) => {
      this.auditData = result;
    });
  }

  async verifyInput() {
    if (this.InputPrimary === '' || this.InputPrimary === '') {
      this.error = true;
      this.errortext = 'Please submit category.';
      return false;
    } else if (this.InputSecondary === '' || this.InputSecondary === "--Select subcategory--") {
      this.error = true;
      this.errortext = 'Please submit subcategory.';
      return false;
    } else if (this.Gender === '') {
      this.error = true;
      this.errortext = 'Please select gender.';
      return false;
    }
    return true;
  }

  changeStatus(data: any) {
    const payload = {
      _id: data._id,
      sync: !data.sync,
    };
    this.apiService.updateAuditData(payload).subscribe(() => {
      // Find the index of the object in the array
      const index = this.auditData.findIndex((item) => item._id === data._id);

      // If the object is found, update it in the array
      if (index !== -1) {
        this.auditData[index] = {
          ...this.auditData[index], // Keep the existing properties
          sync: payload.sync, // Update the sync property
        };
      }
    });
  }

  closealert() {
    this.error = false;
    this.success = false;
  }
}
