import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class WebService {
  readonly ROOT_URL: string;
  readonly ROOT_URL_SCRAPPER: string;
  readonly ROOT_URL_SELLER_API: string;
  constructor(private http: HttpClient) {
    // this.ROOT_URL = "https://12e1-117-233-147-204.ngrok-free.app";
    // this.ROOT_URL = "http://192.168.0.101:3500";
    // this.ROOT_URL = "http://localhost:3501"
    // this.ROOT_URL_SCRAPPER = "http://localhost:4001"
    // this.ROOT_URL_SCRAPPER = "https://a155-2409-40e1-1f-6d16-e17c-c01c-80ee-d7a4.ngrok-free.app"
    this.ROOT_URL_SCRAPPER = "https://scrapper.jesssu.com"
    this.ROOT_URL = "https://api.admin.jesssu.com";
    // this.ROOT_URL_SELLER_API = "http://localhost:3500"
    this.ROOT_URL_SELLER_API = "https://seller.api.jesssu.com";
  }
  post(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload);
  }
  postScrapper(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL_SCRAPPER}/${uri}`, payload);
  }
  postSeller(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL_SELLER_API}/${uri}`, payload);
  }
  getScrapper(uri: string) {
    return this.http.get(`${this.ROOT_URL_SCRAPPER}/${uri}`);
  }
  getSeller(uri: string) {
    return this.http.get(`${this.ROOT_URL_SELLER_API}/${uri}`);
  }
  put(uri: string, payload: Object) {
    return this.http.put(`${this.ROOT_URL}/${uri}`, payload);
  }
  post1(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload);
  }
  postImage(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload, { responseType: 'text' });
  }
  postImage1(uri: string, payload: Object) {
    return this.http.post(`${this.ROOT_URL}/${uri}`, payload, { responseType: 'text' });
  }
  delete(uri: string) {
    return this.http.delete(`${this.ROOT_URL}/${uri}`);
  }
  get(uri: string) {
    return this.http.get(`${this.ROOT_URL}/${uri}`);
  }
  get1(uri: string) {
    return this.http.get(`${this.ROOT_URL}/${uri}`);
  }
  patch(uri: string, payload: Object) {
    return this.http.patch(`${this.ROOT_URL}/${uri}`, payload);
  }
}
