import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../shared/service/api.service';
import { ProductimageService } from '../../../shared/service/fileupload.service';

import { ActivatedRoute, Router } from "@angular/router";
import { UserModel, Accounts, SubscriptionModel } from 'src/app/shared/models/datamodel';
import * as $ from 'jquery';

@Component({
  selector: 'app-gstupload',
  templateUrl: './gstupload.component.html',
  styleUrls: ['./gstupload.component.scss']
})
export class GstuploadComponent implements OnInit {
  public errortext = ""
  public successtext = ""
  public error = false
  public success = false
  public isManufacturerNameAvailable: Object = false;
  sellerdata: any;
  admindata: any;
  public userdata = new UserModel()
  selectedFiles: any;
  FileUploadStatus: boolean;
  currentFileUpload: any;
  public subscriptiondata: any;
  public token: any;
  manufacturer: any;
  selected: any[];
  public ADMIN: any;

  constructor(private apiService: ApiService, private router: Router, private imageuploadService: ProductimageService) {
    this.userdata = JSON.parse(window.localStorage.getItem("USERDETAILS"))
    this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"))
    this.admindata = JSON.parse(window.localStorage.getItem("admin-info"))
    this.subscriptiondata = JSON.parse(window.localStorage.getItem("SUBSCRIPTION"))
    this.ADMIN = JSON.parse(window.localStorage.getItem("ADMIN"))
    this.token = JSON.parse(window.localStorage.getItem("TOKEN"))
    this.manufacturer = JSON.parse(window.localStorage.getItem("MANUFACTURER"))
    if (this.admindata == null || this.admindata == undefined) {
      if (this.sellerdata == null || this.sellerdata == undefined) {
        if (this.userdata == null || this.userdata == undefined) {
          this.router.navigateByUrl("/auth/login")
        }
      }
    }

  }

  ngOnInit(): void {
    this.getusersubscriptiondetails()
    this.getuserdetails(this.userdata._id, this.userdata.token)
  }

  getuserdetails(id, token) {
    const payload = {
      token: this.userdata.token,
      email: this.userdata.email

    }
    const userid = id

    this.apiService.getuserdetails(userid, payload).subscribe((res: UserModel) => {
      this.userdata = res;
      localStorage.setItem("USERDETAILS", JSON.stringify(res));
      this.selected = [];
      this.selected.push(res)
      localStorage.setItem("seller-info", JSON.stringify(this.selected));
    })
  }

  getusersubscriptiondetails() {
    const payload = {
      userid: this.sellerdata[0]._id,
      token: this.token,
      manufacturer: this.sellerdata[0].manufacturer
    }
    this.apiService.getuserSubscriptiondetails(payload).subscribe((res: SubscriptionModel[]) => {
      if (res.length > 1) {
        this.subscriptiondata = res[res.length - 1]
      }
      else {
        this.subscriptiondata = res[0]
      }
      console.log(this.userdata)
    })

  }

  selectFile(event, type) {
    this.selectedFiles = event.target.files;
    this.upload(type);
  }

  upload(type) {
    this.FileUploadStatus = true;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.imageuploadService.pushFileToStorageforGST(this.currentFileUpload).subscribe(res => {
      this.FileUploadStatus = false;
      if (type == 'main') {
        this.userdata.gst_file_link = res.toString();
        this.userdata.gst_file_link = res.toString();
        console.log(this.userdata)
      }
    });
  }
  close() {
    this.error = false;
    this.success = false;
  }

  verifyManufacturer(manufacturer) {
    let payload = { "manufacturer": manufacturer }
    this.apiService.checkManufacturerAvailibility(payload).subscribe(res => {
      this.isManufacturerNameAvailable = res;
      if (!res) {
        this.errortext = 'This name is already taken';
        this.error = true;
        this.success = false;
      }
      else {
        this.error = false;
        this.success = true;
        this.successtext = 'This name is available'
      }
    }
    );


  }
  savegstdata() {

    const payload = {
      token: this.userdata.token,
      email: this.userdata.email

    }

    if (this.userdata.manufacturer != undefined && this.userdata.manufacturer != "") {
      let isManufacturereAvailable = this.verifyManufacturer(this.userdata.manufacturer);
      this.apiService.updateuser(this.userdata._id, this.userdata).subscribe((res) => {
        this.getuserdetails(this.userdata._id, payload)
        this.success = true;
        this.error = false;
        this.successtext = "Manufacturer details saved successfully! Please save GST and Bank details or skip for now"
        localStorage.setItem("MANUFACTURER", JSON.stringify(true))
      })
    }
    else if (this.userdata.manufacturer == null || this.userdata.manufacturer == undefined || this.userdata.manufacturer == "") {
      this.error = true;
      this.success = false
      this.errortext = "Please update manufacturer details"
      localStorage.setItem("MANUFACTURER", JSON.stringify(false))
    }
    else if (this.userdata.gst_number == null || this.userdata.gst_number == undefined) {
      this.error = true;
      this.success = false
      this.errortext = "Please submit gst number"
      if (this.userdata.gst_file_link == null || this.userdata.gst_file_link == undefined) {
        this.error = true;
        this.success = false
        this.errortext = "Please upload gst file"
      } else {
        const payload = {
          token: this.userdata.token,
          email: this.userdata.email

        }
        this.apiService.updateuser(this.userdata._id, this.userdata).subscribe((res) => {

          this.getuserdetails(this.userdata._id, payload)
          this.success = true;
          this.error = false;
          this.successtext = "Details updated successfully!"
          localStorage.setItem("MANUFACTURER", JSON.stringify(true))
          if (this.subscriptiondata == null) {
            this.router.navigateByUrl("/subscription-selection")
          }
          else {
            this.router.navigateByUrl("/dashboard/default")
          }
        })
      }

    }

  }

  skiptodashboard() {
    // debugger;

    if (this.userdata.manufacturer == null || this.userdata.manufacturer == undefined || this.userdata.manufacturer == "") {
      this.error = true;
      this.errortext = "Please update manufacturer details"
    }
    else {
      if (this.subscriptiondata != undefined) {
        if (this.subscriptiondata != false) {
          this.router.navigateByUrl("/dashboard/default")
        }
        else {
          this.router.navigateByUrl("/subscription-selection")
        }
      } else {
        this.router.navigateByUrl("/subscription-selection")
      }
    }
  }
}
// Get this script ready when the page loads
$(document).ready(function () {
  //   Create a function
  $(".scrollUp").click(function (event) {
    //       Select the body of the page and scroll down by 650 pixels worth
    $("html, body").animate({ scrollTop: "+=650px" }, 800);
  });
});
