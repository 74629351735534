import { Component, OnInit } from '@angular/core';
import { listCouponsDB } from 'src/app/shared/tables/list-coupon';
import { ApiService } from '../../shared/service/api.service';
import Swal from 'sweetalert2';

// import { map } from "rxjs/operators/map";
import {
  ProductModel,
  SubscriptionModel,
  UserModel,
} from '../../shared/models/datamodel';
import { Router } from '@angular/router';
import { NotificationModel } from '../../shared/models/datamodel';
import { ProductimageService } from '../../shared/service/fileupload.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user-seller',
  templateUrl: './user-seller.component.html',
  styleUrls: ['./user-seller.component.scss'],
})
export class UserSellerComponent implements OnInit {
  [x: string]: any;
  selectedFiles: FileList;
  currentFileUpload: File;
  FileUploadStatus: Boolean;
  public id: String;
  public sellers: UserModel[];
  public jesssuusers: UserModel[];
  selectedFile: File = null;
  public alltokens = [];
  public coupon = null;
  public jessuplatformusers = [];
  public notificationModel = new NotificationModel();
  public selected = [];
  public ID: String;
  public useradmin: any;
  checked: boolean;
  public sellerdata: any;
  public admindata: any;
  public userdata: any;
  status: any;
  public target_user: string;
  public messsage: string;
  public notification_title: string;
  public notification_image: string;
  public pageview = true;
  public plans: any = []
  public errortext = '';
  public successtext = '';
  public error = false;
  public success = false;
  readonly REDIRECT_URL = "https://seller.jesssu.com"
  public showPopup: boolean = false;
  public selectedSellerForPlan: any = null;
  constructor(
    private apiService: ApiService,
    public router: Router,
    public productimageservice: ProductimageService,
  ) {
    //localStorage.removeItem("seller-info");

    this.sellerdata = JSON.parse(window.localStorage.getItem('seller-info'));
    this.admindata = JSON.parse(window.localStorage.getItem('admin-info'));
    this.token = JSON.parse(window.localStorage.getItem('TOKEN'));
    if (this.admindata == null) {
      this.router.navigate(['/page-not-found']);
    }
    this.getAllPlatformUsers();
    this.getPlans()
  }

  isAllCheckBoxChecked() {
    return this.selected.every((p) => p.checked);
  }


  private serialize(obj: any): string {
    return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
  }


  onSelect(event, selecteduser) {
    if (event.target.checked) {
      var checkboxes = document.getElementsByTagName('input');
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          if (checkboxes[i].id != event.currentTarget.id) {
            checkboxes[i].checked = false;
          }
        }
      }
    }
    // this.selected = [];
    // this.selected.push(selecteduser);
    // localStorage.removeItem("seller-info");
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    // localStorage.setItem("seller-info", JSON.stringify(this.selected));

    // const payload = {
    //   userid: selecteduser._id,
    //   token: this.admindata[0].token,
    //   manufacturer: selecteduser.manufacturer
    // }
    // this.apiService.getuserSubscriptiondetails(payload).subscribe((res: SubscriptionModel[]) => {
    //   if (res.length > 1) {
    //     this.subscriptiondata = res[res.length - 1]
    //     let subscriptiondate = new Date(this.subscriptiondata.createdAt)
    //     let subscriptionplan = this.subscriptiondata.subscriptiontype
    //     let planenddate = new Date(new Date().setDate(subscriptiondate.getDate() + 30));
    //     if (planenddate > new Date()) {
    //       localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
    //       localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
    //       this.successtext = "Subscription available"
    //       this.subscriptionstatus = true
    //       this.error = false
    //       this.success = true
    //     }
    //     else {
    //       alert("user subscription not available")
    //     }
    //   }
    //   else if (res.length == 1) {
    //     this.subscriptiondata = res[0]
    //     let subscriptionplan = this.subscriptiondata.subscriptiontype
    //     let subscriptiondate = new Date(this.subscriptiondata.createdAt)
    //     let planenddate = new Date(new Date().setDate(subscriptiondate.getDate() + 30));
    //     if (planenddate > new Date()) {
    //       localStorage.setItem("SUBSCRIPTION", JSON.stringify(true))
    //       localStorage.setItem("SUBSCRIPTIONPLAN", JSON.stringify(subscriptionplan))
    //       this.successtext = "Subscription available"
    //       this.error = false
    //       this.success = true
    //       this.subscriptionstatus = true
    //     } else {
    //       alert("user subscription not available")
    //     }
    //   }
    //   else {

    //     alert("user subscription not available")

    //   }
    // })
    const payload = {
      userid: selecteduser.email,
      token: this.admindata[0].token,
    };
    const userid = selecteduser._id;
    // debugger;
    this.apiService.getuserdetails(userid, payload).subscribe((res: any) => {
      const selleruserdetails = {
        IsActive: res.IsActive,
        accountstatus: res.accountstatus,
        email: res.email,
        firstname: res.firstname,
        lastname: res.lastname,
        manufacturer: res.manufacturer,
        phone: res.phone,
        role: res.role,
        token: this.admindata[0].token,
        _id: res._id,
      };
      const queryParams = this.serialize(selleruserdetails);
      const url = `${this.REDIRECT_URL}/auth/login?${queryParams}`;
      window.open(url, '_blank');

      // localStorage.setItem('USERDETAILS', JSON.stringify(res));
      // this.selected = [];
      // this.selected.push(res);
      // localStorage.setItem('seller-info', JSON.stringify(this.selected));

      // if (res) {
      //   this.userdetails = res;
      //   this.selected = [];
      //   this.selected.push(res);
      //   localStorage.setItem('seller-info', JSON.stringify(this.selected));
      //   let role = this.userdetails.role;
      //   let accountstatus = this.userdetails.accountstatus;
      //   localStorage.setItem('ACCOUNTSTATUS', JSON.stringify(accountstatus));
      //   if (this.userdetails.manufacturer == null) {
      //     this.errortext =
      //       'Seller still not updated the Manufacturer field. Please ask seller to update Manufacturer details or select another user';
      //     this.error = true;
      //     // alert("Seller still not updated the Manufacturer field. Please ask seller to update Manufacturer details or select another user")
      //   } else {
      //     const payload = {
      //       userid: this.userdetails._id,
      //       token: this.admindata[0].token,
      //       manufacturer: this.userdetails.manufacturer,
      //     };
      //     this.successtext = 'Checking subscription details...';
      //     this.error = false;
      //     this.success = true;
      //     this.apiService
      //       .getuserSubscriptiondetails(payload)
      //       .subscribe((res: SubscriptionModel[]) => {
      //         if (res.length > 1) {
      //           this.subscriptiondata = res[0];
      //           let subscriptiondate = new Date(
      //             this.subscriptiondata.createdAt
      //           );
      //           let subscriptionplan = this.subscriptiondata.subscriptiontype;
      //           let planenddate = new Date(
      //             new Date().setDate(subscriptiondate.getDate() + 30)
      //           );
      //           if (planenddate > new Date()) {
      //             localStorage.setItem('SUBSCRIPTION', JSON.stringify(true));
      //             localStorage.setItem(
      //               'SUBSCRIPTIONPLAN',
      //               JSON.stringify(subscriptionplan)
      //             );
      //             this.successtext = 'Subscription available';
      //             this.subscriptionstatus = true;
      //             this.error = false;
      //             this.success = true;
      //           } else {
      //             this.errortext = 'Subscription ended';
      //             this.subscriptionstatus = false;
      //             this.error = true;
      //             this.success = false;
      //             localStorage.setItem('SUBSCRIPTION', JSON.stringify(false));
      //           }
      //         } else if (res.length == 1) {
      //           this.subscriptiondata = res[0];
      //           let subscriptionplan = this.subscriptiondata.subscriptiontype;
      //           let subscriptiondate = new Date(
      //             this.subscriptiondata.createdAt
      //           );
      //           let planenddate = new Date(
      //             new Date().setDate(subscriptiondate.getDate() + 30)
      //           );
      //           if (planenddate > new Date()) {
      //             localStorage.setItem('SUBSCRIPTION', JSON.stringify(true));
      //             localStorage.setItem(
      //               'SUBSCRIPTIONPLAN',
      //               JSON.stringify(subscriptionplan)
      //             );
      //             this.successtext = 'Subscription available';
      //             this.error = false;
      //             this.success = true;
      //             this.subscriptionstatus = true;
      //           } else {
      //             this.errortext = 'Subscription ended';
      //             this.error = true;
      //             this.success = false;
      //             this.subscriptionstatus = false;
      //             localStorage.setItem('SUBSCRIPTION', JSON.stringify(false));
      //           }
      //         } else {
      //           this.errortext = 'Subscription not available';
      //           this.error = true;
      //           this.success = false;
      //           localStorage.setItem('SUBSCRIPTION', JSON.stringify(false));
      //         }
      //         let manufacturer = this.userdetails.manufacturer;

      //         if (
      //           manufacturer == null ||
      //           manufacturer == undefined ||
      //           manufacturer == ''
      //         ) {
      //           if (
      //             this.userdetails.gst_number == null ||
      //             this.userdetails.gst_number == undefined ||
      //             this.userdetails.gst_number == ''
      //           ) {
      //             localStorage.setItem('GST', JSON.stringify(false));
      //             localStorage.setItem('MANUFACTURER', JSON.stringify(false));
      //             this.router.navigateByUrl('/auth/gstupload');
      //           } else {
      //             localStorage.setItem('GST', JSON.stringify(true));
      //             localStorage.setItem('MANUFACTURER', JSON.stringify(false));
      //             this.router.navigateByUrl('/auth/gstupload');
      //           }
      //         } else if (
      //           this.userdetails.gst_number == null ||
      //           this.userdetails.gst_number == undefined ||
      //           this.userdetails.gst_number == ''
      //         ) {
      //           localStorage.setItem('GST', JSON.stringify(false));
      //           this.router.navigateByUrl('/auth/gstupload');
      //           localStorage.setItem('MANUFACTURER', JSON.stringify(true));
      //           this.router.navigateByUrl('/auth/gstupload');
      //         } else {
      //           localStorage.setItem('GST', JSON.stringify(true));
      //           localStorage.setItem('MANUFACTURER', JSON.stringify(true));
      //           if (this.subscriptionstatus == true) {
      //             // let currentUrl = this.router.url;
      //             // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      //             //     this.router.navigate([currentUrl]);
      //             // });
      //             this.router.navigate(['/auth/gstupload']);
      //           } else {
      //             this.router.navigateByUrl('/subscription-selection');
      //           }
      //         }
      //       });
      //   }
      // }
    });
    // this.sellerdata = JSON.parse(window.localStorage.getItem("seller-info"));
  }

  onSelect1(event, selecteduser) {
    var checkboxes = document.getElementsByTagName('input');
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        if (checkboxes[i].id != event.currentTarget.id) {
          checkboxes[i].checked = false;
        }
      }
    }
    this.selected = [];
    this.selected.push(selecteduser);
    localStorage.removeItem('user-info');
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('user-info', JSON.stringify(this.selected));
    this.userdata = JSON.parse(window.localStorage.getItem('user-info'));
  }

  ngOnInit() {
    this.sellerdata = JSON.parse(window.localStorage.getItem('seller-info'));
    this.admindata = JSON.parse(window.localStorage.getItem('admin-info'));
    this.userdata = JSON.parse(window.localStorage.getItem('user-info'));
    // this.id = this.sellerdata[0].role;

    if (this.admindata == null) {
      if (this.sellerdata[0].role == 'basic') {
        alert(
          'This option is not available for sellers. Please contact with admin.'
        );
        this.router.navigate(['/dashboard/default']);
      }
    }
  }
  removeselectedseller() {
    localStorage.removeItem('seller-info');
    localStorage.removeItem('user-info');

    // this.router.navigate(['/dashboard/default'])
  }

  getAllPlatformUsers() {
    this.apiService
      .getUsers(this.admindata[0].token)
      .subscribe((sellers: any[]) => (this.sellers = sellers));

    this.apiService
      .getjessuplatformUsers(this.admindata[0].token)
      .subscribe(
        (jesssuusers: any[]) => (this.jessuplatformusers = jesssuusers)
      );
  }

  getUsers() {
    this.sellerdata = JSON.parse(window.localStorage.getItem('seller-info'));
    this.admindata = JSON.parse(window.localStorage.getItem('admin-info'));
    if (this.admindata != null) {
      const payloadforgetUsers = {
        userid: this.admindata[0]._id,
        token: this.admindata[0].token,
      };
      this.apiService
        .getadminUsers(payloadforgetUsers)
        .subscribe((res: any[]) => {
          this.useradmin = res;
        });
    }
  }

  onDelete($selected) {
    console.log(this.selected);
  }
  Onclickcheckactivitydetails(id) {
    localStorage.removeItem('url');

    this.ID = id;
    // this.ID.push(id);
    localStorage.setItem('url', JSON.stringify(this.ID));
    this.router.navigate(['/users/user-activity']);
  }

  Onclickcheckfriends(id) {
    localStorage.removeItem('url');

    this.ID = id;
    // this.ID.push(id);
    localStorage.setItem('url', JSON.stringify(this.ID));
    this.router.navigate(['/friends']);
  }

  Onclickcheckscreendetails(id) {
    localStorage.removeItem('url');

    this.ID = id;
    localStorage.setItem('url', JSON.stringify(this.ID));
    this.router.navigate(['/users/onboarding-screen']);
  }

  // Onclick3(id) {
  //   localStorage.removeItem("url");
  //   this.ID = id;
  //   localStorage.setItem("url", JSON.stringify(this.ID));
  //   this.router.navigate(['/media/activity/update']);
  // }

  changeStatus(coupon) {

    this.selected = [];
    this.selected.push(coupon);
    console.log(this.selected);

    if (this.selected[0].IsActive == false) {
      this.id = this.selected[0]._id;
      this.selected[0].IsActive = true;
      this.selected[0].token = this.token;
      this.apiService.updateuser(this.id, this.selected[0])
        .subscribe((res) => {
          this.success = true;
          this.successtext = "User status changed successfully"
          // alert("User status changed successfully");
          localStorage.removeItem("seller-info");

        })

    }
    else {
      this.id = this.selected[0]._id;
      this.selected[0].IsActive = false;
      this.apiService.updateuser(this.id, this.selected[0])
        .subscribe((res) => {
          this.success = true;
          this.successtext = "User status changed successfully"
          // alert("User status changed Successfully");
          localStorage.removeItem("seller-info");

        })

    }

  }
  onSelectedFile(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  upload(type) {
    this.currentFileUpload = this.selectedFiles.item(0);

    // this.FileUploadStatus = false;
    this.productimageservice
      .pushFileToStorage(this.currentFileUpload)
      .subscribe((res) => {
        this.notificationModel.notification_image = res.toString();

        if (type == 'main') {
          this.notificationModel.notification_image = res.toString();
        }
      });
  }

  selectFile(event, type) {
    this.selectedFiles = event.target.files;
    this.upload(type);
  }

  onSubmit(form: NgForm) {
    if (this.admindata != null || this.admindata != undefined) {
      this.notificationModel.token = this.admindata.token;
    } else {
      this.notificationModel.token = this.sellerdata.token;
    }

    if (this.notificationModel.target_user == 'Seller') {
      this.apiService.getsellertoken().subscribe((res: any[]) => {
        this.alltokens = res;
        this.notificationModel.tokens = this.alltokens;
      });
    } else {
      this.apiService.getusertoken().subscribe((res: any[]) => {
        this.alltokens = res;
        this.notificationModel.tokens = this.alltokens;
      });
    }

    // console.log(this.notificationModel);
    if (this.notificationModel.target_user == null) {
      this.error = true;
      this.errortext = 'Please Select the User';

      // alert("Please Select the User")
    } else if (this.notificationModel.message == null) {
      this.error = true;
      this.errortext = 'Please Input a message';
      // alert("Please Input a message")
    } else if (this.notificationModel.notification_title == null) {
      this.error = true;
      this.errortext = 'Please select a title';
      // alert("Please select a title")
    } else if (this.notificationModel.notification_image == null) {
      this.error = true;
      this.errortext = 'Please selet an image';
      // alert("Please selet an image")
    } else if (this.notificationModel.target_user == 'Seller') {
      this.apiService.sendnotification(this.notificationModel).subscribe(
        (re) => {
          this.success = true;
          this.successtext = 'notification send successfully.';
          // alert("notification send successfully.")
        },
        (error) => {
          alert(this.notificationModel);
        }
      );
    } else {
      this.apiService.sendnotificationuser(this.notificationModel).subscribe(
        (re) => {
          this.success = true;
          this.successtext = 'notification send successfully.';
          // alert("notification send successfully.")
        },
        (error) => {
          alert(this.notificationModel);
        }
      );
    }
  }

  searchseller() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById('myInputforseller');
    if (input.value.length > 1) {
      const payload = {
        search: input.value,
        token: this.admindata[0].token,
      };
      this.apiService
        .searchseller(payload)
        .subscribe((sellers: any[]) => (this.sellers = sellers));
    } else {
      this.apiService
        .getUsers(this.admindata[0].token)
        .subscribe((sellers: any[]) => (this.sellers = sellers));
    }
  }

  searchuser() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById('myInputforuser');
    if (input.value.length > 1) {
      const payload = {
        search: input.value,
        token: this.admindata[0].token,
      };
      this.apiService
        .searchuser(payload)
        .subscribe(
          (jesssuusers: any[]) => (this.jessuplatformusers = jesssuusers)
        );
    } else {
      this.apiService
        .getjessuplatformUsers(this.admindata[0].token)
        .subscribe(
          (jesssuusers: any[]) => (this.jessuplatformusers = jesssuusers)
        );
    }
  }
  generatePassword(length: number = 12): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }
  getCurrentDate(): any {
    return this.formatDate(new Date());
  }
  getEndDate(days: number = 30): any {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + days);
    return this.formatDate(endDate);
  }
  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  resetPassword(user: any) {
    const payload = {
      "email": user.email,
      "password": this.generatePassword()
    }
    this.apiService.resetSellerPassword(payload).subscribe(
      (res) => {
        this.getAllPlatformUsers();
        Swal.fire({
          text: `Password reset successfully for seller ${user.email}`,
          icon: 'info',
          confirmButtonText: 'Ok'
        });
      },
      (error) => {
        Swal.fire({
          text: `error.message`,
          icon: 'info',
          confirmButtonText: 'Ok'
        });
      }
    );
  }
  getPlans() {
    this.apiService.getPlans().subscribe(
      (res: any) => {
        this.plans = res.plans;
      }
    );
  }
  async closePopup() {
    this.selectedSellerForPlan = null
    this.showPopup = false
  }
  async addPasswordAndSubscription(user: any) {
    this.selectedSellerForPlan = user
    this.showPopup = true
  }

  async getsubscription(data) {
    const plan = data.title;
    let price = data.amount;
    const description = `${data.title} plan has been selected by the User`;
    const type = `1 month ${plan} plan`;
    const email = this.selectedSellerForPlan.email
    const manufacturer = this.selectedSellerForPlan.manufacturer
    const startDate = new Date();
    let endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);
    if (endDate.getDate() < startDate.getDate()) {
      endDate.setDate(0); // Set to the last day of the previous month
    }
    if (price === 0) {

      const subscriptionPayload = {
        plan: plan,
        price: price,
        description: `${plan} Plan`,
        type,
        email,
        startDate,
        endDate,
        app_name: "Jesssu Seller Central",
        paymentStatus: true,
        manufacturer
      };
      const addSubscriptionData: any = await this.apiService.addSubscription(subscriptionPayload).toPromise();
      this.selectedSellerForPlan = null;
      this.showPopup = false;
      this.getAllPlatformUsers();
      Swal.fire({
        text: `The ${plan} plan has been activated successfully.`,
        icon: 'info',
        confirmButtonText: 'Ok'
      });

    } else {

      try {
        const razorpayPayload = {
          amount: price * 100, // Amount in paisa
          description: description
        };

        const razorpayData: any = await this.apiService.createRazorpayOrder(razorpayPayload).toPromise();

        if (razorpayData.orderId) {
          const options = {
            amount: price * 100, // Amount in paisa
            currency: 'INR',
            name: 'eDgeWrapper',
            description: description,
            order_id: razorpayData.orderId,
            handler: async (response) => {
              const paymentStatus = true;
              const subscriptionPayload = {
                plan,
                price,
                description,
                type,
                email,
                startDate,
                endDate,
                app_name: "Jesssu Seller Central",
                paymentStatus,
                manufacturer
              };

              try {
                const addSubscriptionData: any = await this.apiService.addSubscription(subscriptionPayload).toPromise();
                if (addSubscriptionData.success) {
                  const paymentPayload = {
                    plan: addSubscriptionData.subscription.plan,
                    price: addSubscriptionData.subscription.price,
                    email: addSubscriptionData.subscription.email,
                    app_name: addSubscriptionData.subscription.app_name,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature
                  };

                  const addData: any = await this.apiService.addPayment(paymentPayload).toPromise();
                  if (addData.success) {
                    this.getAllPlatformUsers();
                    Swal.fire({
                      text: `The ${plan} plan has been activated successfully.`,
                      icon: 'info',
                      confirmButtonText: 'Ok'
                    });
                  }

                } else {
                  throw new Error('Failed to save subscription data');
                }
              } catch (error) {
                console.error('Error while saving subscription data:', error);
                this.messageColor = 'red'
                this.message = `Error while saving subscription data. Please try again.`
              }
            },
            modal: {
              ondismiss: function () {
                this.messageColor = 'red'
                this.message = `Payment canceled.`
              }
            }
          };

          const rzp = new Razorpay(options);
          rzp.open(); // Open the Razorpay payment modal
        } else {
          throw new Error('Failed to create Razorpay order');
        }
        this.selectedSellerForPlan = null;
        this.showPopup = false;
      } catch (error) {
        console.error('API request error:', error);
      }
    }

  }
}
