<p class="scraping-status">{{successtext}}</p>
<div class="jesssu-seller-banner-wrap">
    <div class="jesssu-seller-contant">
        <div class="jesssu-header">
            <div class="jesssu-header-row">
                <div>
                </div>
                <div class="rightside-profile">
                </div>

            </div>
        </div>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body" style="overflow: auto;">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab>
                            <ng-template ngbTabTitle>Scrape Products</ng-template>
                            <ng-template ngbTabContent>

                                <div class="applications-box6">
                                    <div class="col-md-9">
                                        <div class="order-row2">
                                            <h4>Select Channel</h4>
                                        </div>
                                        <div class="submit-form-group">
                                            <select name="acc_name" class="submit-form-control"
                                                [(ngModel)]="selectedChannel" (change)="onChannelSelect($event)">
                                                <option *ngFor="let channel of channelList"
                                                    [value]="channel.channelName">{{ channel.channelName }}</option>
                                            </select>
                                        </div>
                                        <div class="submit-form-group">
                                            <input type="text" placeholder="." class="submit-form-control"
                                                [(ngModel)]="channelURL" disabled />
                                        </div>
                                    </div>
                                    <div class="info-container" *ngIf="keywords.length!=0">
                                        <div class="info-box" *ngFor="let keyword of keywords, let i = index">
                                            <div class="info-box-header">
                                                <div class="brand-edit-btn" >
                                                    <img src="../../../../assets/images/dashboard/Icon material-edit.svg"
                                                        alt="" (click)="openCloseBox('edit', keyword, i, 'keyword')" />
                                                        <img src="../../../../../assets/images/Group 210.svg"
                                                        alt="" (click)="deleteKeywordURL(i, 'keyword')" />
                                                </div>
                                            </div>
                                            <div class="info-content">
                                                <div class="info-item">Keyword: {{keyword?.keyword}}</div>
                                                <div class="info-item">Category: {{keyword?.Primary}}</div>
                                                <div class="info-item">Sub Category: {{keyword?.Secondary}}</div>
                                                <div class="info-item">Gender: {{keyword?.Gender}}</div>
                                                <div class="info-item">Sync Status: {{keyword?.sync}}</div>
                                            </div>
                                        </div>
                                        <img [style]="{'height': '50px', 'width': '50px', 'position':'center', 'cursor':'pointer'}"
                                            src="../../../../assets/images/dashboard/add.png" alt="" (click)="openCloseBox('add', {}, 0, 'keyword')"/>

                                    </div>
                                    <div class="info-container" *ngIf="URLs.length!=0">
                                        <div class="info-box" *ngFor="let keyword of URLs, let i = index">
                                            <div class="info-box-header">
                                                <div class="brand-edit-btn" (click)="openCloseBox('edit', keyword, i, 'URL')">
                                                    <img src="../../../../assets/images/dashboard/Icon material-edit.svg"
                                                        alt="" />
                                                </div>
                                                <div class="brand-edit-btn" (click)="deleteKeywordURL(i, 'URL')">
                                                    <img src="../../../../../assets/images/Group 210.svg"
                                                        alt="" />
                                                </div>
                                               
                                            </div>
                                            <div class="info-content">
                                                <div class="info-item">Keyword: {{keyword?.URL}}</div>
                                                <div class="info-item">Category: {{keyword?.Primary}}</div>
                                                <div class="info-item">Sub Category: {{keyword?.Secondary}}</div>
                                                <div class="info-item">Gender: {{keyword?.Gender}}</div>
                                                <div class="info-item">Sync Status: {{keyword?.sync}}</div>
                                            </div>
                                        </div>
                                        <img [style]="{'height': '50px', 'width': '50px', 'position':'center', 'cursor':'pointer'}"
                                        src="../../../../assets/images/dashboard/add.png" alt="" (click)="openCloseBox('add', {}, 0, 'URL')"/>
                                    </div>
                                    <div class="applications-table-row" *ngIf="addSection==true">
                                        <div class="col-md-9">
                                            <div class="order-row2">
                                                <h4>Add Keywords</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <input type="text" placeholder="" [(ngModel)]="inputText"
                                                    class="submit-form-control">
                                            </div>
                                            <div *ngIf="categorybox">
                                                <div class="order-row2">
                                                    <h4>
                                                        Category Selection*</h4>
                                                </div>
                                                <div class="submit-form-group">
                                                    <select name="acc_name" [(ngModel)]="Primary"
                                                        class="submit-form-control" (change)="selectChange()">
                                                        <option [ngValue]="" copy>--Select category--
                                                        </option>
                                                        <option *ngFor="let category of categories"
                                                            [ngValue]="category.categoryname">
                                                            {{category.gender}} : {{category.categoryname}}
                                                        </option>
                                                    </select>
                                                    <input class="submit-form-control" (input)="onCategoryChange()"
                                                        type="text"
                                                        placeholder="Select category from dropdown or create new."
                                                        [(ngModel)]="InputPrimary">
                                                </div>
                                            </div>
                                            <div>
                                                <div class="order-row2">
                                                    <h4>
                                                        Subcategory Selection*</h4>
                                                </div>

                                                <div class="submit-form-group">
                                                    <select name="acc_name" [(ngModel)]="Secondary"
                                                        class="submit-form-control" (change)="selectsubChange()">
                                                        <option [ngValue]="" copy>--Select subcategory--
                                                        </option>
                                                        <option *ngFor="let subcategory of Subcategories"
                                                            [ngValue]="subcategory.subcategoryname">
                                                            {{subcategory.subcategoryname}}
                                                        </option>
                                                    </select>
                                                    <input class="submit-form-control" (input)="onSubCategoryChange()"
                                                        type="text"
                                                        placeholder="Select sub-category from dropdown or create new."
                                                        [(ngModel)]="InputSecondary">
                                                </div>
                                            </div>
                                            <div>
                                                <div class="order-row2">
                                                    <h4>
                                                        Gender</h4>
                                                </div>
                                                <div class="submit-form-group">
                                                    <select name="acc_name" class="submit-form-control"
                                                        [(ngModel)]="Gender" (change)="onGenderSelect($event)">
                                                        <option value="">--Select gender --</option>
                                                        <option value="Men">Men</option>
                                                        <option value="Women">Women</option>
                                                        <option value="Unisex">Unisex</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="order-row2">
                                                <h4>Sync Status</h4>
                                            </div>
                                            <div class="submit-form-group">
                                                <select name="acc_name" class="submit-form-control"
                                                    [(ngModel)]="selectedStatus" (change)="onStatusSelect($event)">
                                                    <option value="false">False</option>
                                                    <option value="true">True</option>
                                                </select>
                                            </div>
                                            <div class="feed-action">

                                                <form #notificationForm="ngForm" id="notificationForm"
                                                    class="needs-validation notification-add" novalidate="">
                                                    <router-outlet></router-outlet>
                                                    <div class="user-submit-row">
                                                        <button class="feed-action-link btn-action-link" type="submit"
                                                            value="Submit" (click)="addKeyword()">{{addEditType==="edit"?"Update":"Add"}}</button>
                                                        <div class="alert" *ngIf="error">
                                                            <span class="closebtn" (click)="closealert()">&times;</span>
                                                            <strong>{{errortext}}</strong>
                                                        </div>
                                                        <div class="response" *ngIf="success">
                                                            <span class="closebtn" (click)="closealert()">&times;</span>
                                                            <strong>{{successtext}}</strong>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </ngb-tab>

                        <ngb-tab>
                            <ng-template ngbTabTitle>Scraping History</ng-template>
                            <ng-template ngbTabContent>


                                <div class="user-applications-box">


                                    <div class="fixTableHead">

                                        <table class="table table-striped custom-table">
                                            <thead>
                                                <tr>

                                                    <th scope="col">ACTION</th>
                                                    <th scope="col">KEYWORD</th>
                                                    <th scope="col">SCRAPED PRODUCT</th>
                                                    <th scope="col">PRODUCT UPDATED</th>
                                                    <th scope="col">FAILED COUNT</th>
                                                    <th scope="col">SYNC STATUS</th>
                                                    <th scope="col">ERROR DETAILS</th>
                                                    <th scope="col">DATE & TIME</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of auditData">





                                                    <td>
                                                        {{data.actiontype}}
                                                    </td>
                                                    <td>
                                                        {{data.search}}
                                                    </td>
                                                    <td>
                                                        {{data.totalScraped}}
                                                    </td>
                                                    <td>
                                                        {{data.updateCount}}
                                                    </td>
                                                    <td>
                                                        {{data.failedCount}}
                                                    </td>
                                                    <td>
                                                        <label class="custom-control ios-switch">
                                                            <input type="checkbox" class="ios-switch-control-input"
                                                                id="{{data._id}}" [checked]="data.sync==true"
                                                                lebel="changestatus" disabled> <span
                                                                class="ios-switch-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {{data.error}}
                                                    </td>
                                                    <td>
                                                        {{data.createdAt | date: 'yyyy-MM-dd HH:mm:ss'}}
                                                    </td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>









                </div>
            </div>
        </div>
    </div>
</div>